import React, { useState } from 'react';
import axios from 'axios';
import './refund.css'
import configurations from "../../../configurations";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import RefundPaymentDialogue from './RefundPaymentDialogue';
import { patterns } from './CreatePayment';
import { getTransactionIdValue, getNotesDate, getSubID } from '../../Services/CommonServices';
import PaymentConfirmation from './ConfirmBox';
import { errorMessage } from './CreatePayment'
import ProdLvlRefundDialogue from './ProdLvlRefundDialogue';
import ConfirmTermination from './ConfirmTermination';

const RefundAdjust = 'RefundAdjust';
const RefundPayment = (props) => {

    const [transactionId, setTransactionId] = React.useState('');
    const [transactionIdError, setTransactionIdError] = React.useState(false);
    const [showRefundPaymentVIew, toggleRefundPaymentView] = React.useState(false);
    const [showTransactionDetail, toggleTransactionTableView] = React.useState(false);
    const [transactionDetail, setTransactionDetail] = React.useState([]);
    const [trasctionDataForRefund, setSelectedTransaction] = React.useState(false);

    const [paymentRequest, setPaymentRequest] = useState({ amount: '' });
    const [showConfirm, toggleConfrm] = useState(false);
    const [paymentNumber, setPaymentNumber] = useState(0);
    const [createdDate, setCreatedDate] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0)
    const [isTotalChecked, setIsTotalChecked] = useState(false)
    const [isTerminatedMember, setIsTerminatedMember] = useState(JSON.parse(localStorage.getItem("isTerminatedMember")))

    const changeValue = (event, controlIndx) => {

        let value = event.target.value;
        props.changeValue(value, controlIndx)
    }

    const onFormSubmit = (event) => {
        event.preventDefault();
        // props.makePaymentClick()
    }

    const onRefundClick = (transaction) => {
        // setTrasctionDataForRefund(transaction);
        localStorage.removeItem("selectedID")
        const data = {
            "transactionId": transaction.transactionId,
            "affiliationId": transaction.affiliationId,
            "source": transaction.source
        }
        axios.post(process.env.REACT_APP_transactionBaseUrl + `/adminportal/validateRefund`, data)
        .then(res => {
            if (res.data.code == 204) {
                console.log('00--00-- ', res.data.message);
                props.setAlertMsg(res.data.message);
                props.toggleAlert(true)
            } else {
                console.log("refundpayment.js--",transaction)
                axios.get(`${process.env.REACT_APP_paymentService}transactions/${transaction.transactionId}/components`)
            .then(res => {
                setSelectedTransaction(res.data.data)
                setPaymentNumber(transaction.paymentNumber)
                setCreatedDate(transaction.createdDate)
                toggleRefundPaymentView(true);
            })
            .catch(err => {
            })
            }
        })
    }
    const showRefundBtn = (transaction) => {
        let btnDisabled = false;
        if (transaction.paymentNumber == 0) {
            btnDisabled = true;
        }

        return <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={btnDisabled}
            // className={classes.button}
            // startIcon={<ContactMailIcon />}
            onClick={() => onRefundClick(transaction)}

        >
            {props.selectedPaymentMode == RefundAdjust ? 'Adjust' : 'Refund'}

        </Button>
    }

    const setTransactionIdValue = (e) => {

        let value = e.target.value.trim();
        let _transactionId = getTransactionIdValue(value)

        let isPatternMatched = value.match(patterns.amount);
        setTransactionIdError(!isPatternMatched)
        setTransactionId(_transactionId)
    }

    const getTransactionDetails = () => {
        // addRefundNoteToCSR()
        if (transactionId.length <= 0) {
            return false;
        }
        props.toggleLoader(true);
        setTransactionDetail([])
        toggleTransactionTableView(false)
        let apiURL = configurations.paymentBaseUrl + 'transaction/transactionHistory?searchKey=transactionId&searchValue=' + transactionId;

        axios.get(apiURL).then((resp) => {
            if (resp && resp.data.code == 200) {


                if (resp.data.response.length > 0) {
                    let isSourceIdSame = resp.data.response[0].source == props.memberDetails.memberIdSource;
                    let transactionList = resp.data.response.filter((item) => item.source == props.memberDetails.memberIdSource)
                    setTransactionDetail(transactionList);
                    toggleTransactionTableView(true)

                    // if (isSourceIdSame) {
                    //     setTransactionDetail(resp.data.response);
                    //     toggleTransactionTableView(true)
                    // } else {
                    //     props.setAlertMsg('Transaction details not found!!');
                    //     props.toggleAlert(true)
                    // }

                } else {
                    props.setAlertMsg('Transaction details not found!');
                    props.toggleAlert(true)
                }

            }
            props.toggleLoader(false);
        }).catch((err) => {
            props.setAlertMsg(errorMessage);
            props.toggleAlert(true)
            props.toggleLoader(false)

        });;
    }

    const getTransactionMsg = (transaction) => {
        if (transaction.transactionStatus == 'FAIL') {
            let reason = transaction.refundDescription
            if (transaction.reason) {
                try {
                    reason = JSON.parse(transaction.reason).error_message
                }
                finally {
                    return reason
                }
            }
        }

        if (transaction.reason && transaction.reason.length > 0) {
            return transaction.reason
        }
        return transaction.refundDescription
    }

    const makeRefund = (data, isTerminate) => {
        console.log(trasctionDataForRefund);
        let id = localStorage.getItem("selectedID")
        let sub_id = localStorage.getItem("sub_id")
        if (data) {
            props.toggleLoader(true);
            let resp = ''
            axios.post(`${process.env.REACT_APP_paymentService}transactions/${id || transactionId}/components`, data, true)
                .then((response) => {
                    if (response.data.data.authorizationMessage == "FAILED") {
                        props.setAlertMsg(`Refund Error: <b>${response.data.data.responseText}</b>`)
                      } else {
                        if (isTerminate) {
                            axios.post(`${process.env.REACT_APP_enrollmentBaseUrl}/enrollment/terminate/${sub_id}`, {terminatedReason:"REFUND"})
                            .then(res => {
                                console.log('res: ', res);
                            })
                            .catch(err => {
                                console.log('err: ', err);
                            })
                        }
                        props.setAlertMsg(`Refund of <b>$${response.data.data.amount.slice(1)}</b> against payment number <b>${paymentNumber}</b> has been processed successfully. Refund Transaction Id is <b>${response.data.data.transactionId}</b>`)
                      }
                    props.toggleAlert(true)
                    props.toggleLoader(false);
                    // addRefundNoteToCSR();
                    // setTrasctionDataForRefund(false);
                    // loadTransactionHistory()
                    setTransactionId('')
                    setTransactionDetail([])
                    toggleTransactionTableView(false)
                }).catch((error) => {
                    props.toggleLoader(false);
                    props.toggleAlert(true)
                    if (error.response && error.response.data && error.response.data.errors) {
                        const errorMessage = error.response.data.errors[0];
                        props.setAlertMsg(`Refund Error: <b>${errorMessage}</b>`);
                    } else {
                        props.setAlertMsg(`Refund Error: <b>An error occurred while processing the refund.</b>`);
                    }
                });
                toggleRefundPaymentView(false);
            } else {
                toggleRefundPaymentView(false);
            }
    }


    const addRefundNoteToCSR = () => {
        props.login()
            .then((token) => {
                let memberIdSource = props.memberDetails.memberIdSource;
                axios.get(configurations.csrBaseUrl + 'csrportal/getnotes?memberIdSource=' + memberIdSource, {
                    headers: {
                        Authorization: token
                    }
                })
                    .then((response) => {
                        if (response) {
                            let userData = response.data;
                            addNotes(token, userData)
                        }
                    }).catch((error) => {
                        console.log(error);
                        props.toggleLoader(false);
                    });
            });
    }

    const addNotes = (token, userData) => {
        let newNote = 'Amount Refunded';
        if (props.selectedPaymentMode == RefundAdjust) {
            newNote = 'Amount Adjusted'
        }
        let existingNotes = userData.notes ? userData.notes : '';
        let date = getNotesDate();
        let loggedinUserEmail = localStorage.getItem('email');
        let notesRequest = {
            "memberSSN": userData.memberSSN,
            "csrUser": loggedinUserEmail,
            "notes": 'User:' + loggedinUserEmail + ',' + 'Date:' + date + '\nAudit Trail: ' + newNote + '\n\n' + existingNotes,

        }
        axios.post(configurations.csrBaseUrl + 'csrportal/notes', notesRequest, {
            headers: {
                Authorization: token
            }
        })
            .then((response) => {
                console.log(response)
                props.toggleLoader(false);
            }).catch((error) => {
                console.log(error)
                props.toggleLoader(false);
            });
    }

    const transactionIdOnBlur = (e) => {

        console.log('enter empIdOnBlur')
        getTransactionDetails()
    }

    const closeConfirm = () => {
        toggleConfrm(false)
    }

    const accept = (value) => {
        toggleConfrm(false);
        makeRefund(paymentRequest, value);
    }

    const confirmRefund = (data) => {
        console.log('data--',data)
        toggleConfrm(true);
        setPaymentRequest(data);
    }

    return (
        <div>
            <form onSubmit={onFormSubmit}>
                <Grid container spacing={3}>

                    <Grid item xs={6}>
                        <div style={{
                            textAlign: 'left',
                            paddingLeft: '10%',
                            display: 'flex',
                            // justifyContent: 'space-between'
                        }}>

                            <TextField
                                disabled={props.disableAll}
                                variant="filled" style={{ width: '55%' }} value={transactionId} label='Enter Transaction ID' onChange={(e) => { setTransactionIdValue(e) }} required={true} error={transactionIdError} helperText={transactionIdError ? 'Enter a Valid Transaction ID' : ''}
                                onBlur={transactionIdOnBlur} onKeyPress={(e) => {
                                    if (e.key == 'Enter') {
                                        console.log('enter pressed')
                                        e.target.blur()
                                        // getEMPDetails()
                                    }
                                }} />
                            {/* <Button
                                variant="contained"
                                color="primary"
                                size="small" style={{
                                    marginLeft: '10px',
                                    marginTop: '20px',
                                    textTransform: 'none',
                                    height: '30px',
                                    fontSize: '11px'
                                }}
                                // startIcon={<MailOutlineIcon />}
                                onClick={getTransactionDetails}
                                disabled={transactionIdError || transactionId.length <= 0}
                            >Get Details</Button> */}
                        </div>
                    </Grid>

                    <Grid item xs={12} hidden={!showTransactionDetail}>
                        <Table

                            style={{ width: '90%' }}
                            className="transaction-table"
                            size="small" aria-label="a dense table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell style={{
                                        // maxWidth: '100px'
                                    }}>Transaction ID</TableCell>
                                    <TableCell align="left">Type</TableCell>
                                    <TableCell align="left">Payment Type</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left">Month</TableCell>
                                    {/* <TableCell align="left">Status</TableCell> */}
                                    <TableCell align="left" style={{ maxWidth: '300px' }}>Description</TableCell>
                                    {
                                        true &&
                                        <TableCell align="left">Refund</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    transactionDetail.map((transaction) => {
                                        return (
                                            <TableRow >
                                                <TableCell align="left">{transaction.createdDate}</TableCell>
                                                <TableCell >
                                                    {transaction.transactionId ? transaction.transactionId : ' - '}
                                                </TableCell>
                                                <TableCell align="left">{transaction.indicatorFlag ? transaction.indicatorFlag : '      - '}</TableCell>
                                                <TableCell align="left">{transaction.type}</TableCell>
                                                <TableCell align="left">{transaction.transactionAmount ?
                                                    '$ ' + transaction.transactionAmount.toFixed(2) : '      - '} </TableCell>
                                                <TableCell align="left">{
                                                    transaction.authorizationMessage ? transaction.authorizationMessage : '      - '
                                                }</TableCell>

                                                <TableCell align="left">{transaction.paymentNumber}</TableCell>
                                                <TableCell align="left" style={{ maxWidth: '300px', overflowX: 'auto' }}>{
                                                    getTransactionMsg(transaction)
                                                }</TableCell>

                                                <TableCell align="left">{showRefundBtn(transaction)}</TableCell>



                                            </TableRow>
                                        )
                                    })
                                }

                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>

                <div style={{
                    width: '100%',
                    marginTop: '30px'
                }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{
                            textTransform: 'none',
                        }}
                        // startIcon={<MailOutlineIcon />}
                        onClick={() => {
                            // props.clearAllFields();
                            props.onClick();
                        }}
                    // disabled={disableSaveBtn}
                    >Back To Home</Button>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="submit"
                        style={{
                            marginLeft: '15px',
                            textTransform: 'none',
                        }}
                        // startIcon={<MailOutlineIcon />}
                        onClick={(e) => {
                            // props.makePaymentClick()
                        }}
                        disabled={props.disableAll}
                    >Make Payment</Button> */}



                </div>

            </form>

            {
                showRefundPaymentVIew &&
                <ProdLvlRefundDialogue isOpen={showRefundPaymentVIew}
                    closerefndpayment={() => { toggleRefundPaymentView(false) }}
                    confirmRefund={confirmRefund}
                    trasctionDataForRefund={trasctionDataForRefund}
                    selectedPaymentMode={props.selectedPaymentMode}
                    paymentNumber={paymentNumber}
                    createdDate={createdDate}
                    totalAmount={(v) => setTotalAmount(v)}
                    setIsTotalChecked={setIsTotalChecked}
                    showConfirm={showConfirm}
                    closeConfirm={closeConfirm}
                    paymentRequest={totalAmount}
                    memberDetails={props.memberDetails}
                    accept={accept}
                    isTotalChecked={isTotalChecked}
                    isTerminatedMember={isTerminatedMember}
                />
            }

            {/* <AlertDialog open={showAlert} messageText={alertMsg} closeAlert={() => toggleAlert(false)} /> */}
        </div>
    )
}

export default RefundPayment;