import Axios from "axios";
import configurations from "../../configurations";
import { loginWithPromise } from "../../store/slice/authSlice";

export const commomPost = async (suburl, userData) => {
    let data = {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    };
    const url = configurations.enrollmentBaseUrl + suburl;
    return fetch(url, data)
        .then((response) => {
            if (response.headers.get('content-type').match(/application\/json/)) {
                return response.json();
            }
            return response;

        })
        .catch((e) => {
            //DeviceEventEmitter.emit('API')
        })
}


export const getTransactionIdValue = (value) => {
    let transactionId = value;
    let transactionLimit = 12;
    if (value.length >= transactionLimit) {
        transactionId = transactionId.substr(1)
    }
    let remainingLen = transactionLimit - value.length;
    if (remainingLen <= 0) {
        remainingLen = transactionLimit - transactionId.length;
    }
    // for (let i = 0; i < remainingLen; i++) {
    //     transactionId = '0' + transactionId
    // }
    return transactionId;
}

export const getNotesDate = () => {
    let hours = new Date().getHours();
    let ampm = hours >= 12 ? 'pm' : 'am';
    let str = new Date().getFullYear() - 2000;
    let month = new Date().getMonth() + 1;
    let month1 = '' + month
    let day = '' + new Date().getDate();

    if (month1.length < 2)
        month1 = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    let date = month1 + '/' + day + '/' + str + ' ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() + ' ' + ampm;

    return date;

}

export const getSubID = (source) => {
    Axios.get(`${process.env.REACT_APP_enrollmentBaseUrl}/enrollment/getEnrollMemberInfoById/${source}`)
    .then(res => {
        localStorage.setItem("sub_id", res.data.response.subId)
        return res.data.response.subId
    })
}

export const isTerminatedMember = (source) => {
    let innoOrganization = Buffer.from(`INNOVATION;${new Date()}`).toString('base64')
    Axios.get(`${process.env.REACT_APP_csrBaseUrl}memberportal/member/status/${source}`, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("innobearerToken"),
            'X-Tenant-Id': innoOrganization
        }
    })
    .then(res => {
        console.log('res: ', res.data);
        if (res.data == "Terminated") {
            localStorage.setItem("isTerminatedMember", JSON.stringify(true))
            return true
        } else {
            localStorage.setItem("isTerminatedMember", JSON.stringify(false))
            return false
        }
    })
}