import React, { useState, useEffect } from 'react';
// import apiservice from '../../utils/apiservice';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from 'axios';
// import { connect } from "react-redux";
import customStyle from '../../CSS/stylesheet_UHS';

const useStyles = makeStyles(theme => ({

    root: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    TextFieldFont: {
        "&::placeholder": {
            textOverflow: "ellipsis !important",
            color: "blue",
            fontSize: '5px'
        }
    },
    grid: {
        // paddingTop: '10px',
        paddingBottom: '5px',
        background: 'white',
        marginTop: '5px;'
    },
    searchGrid: {
        // paddingTop: '15px !important'
    },
    formControl: {
        width: '80%',
        marginLeft: '15px'
    },
    searchBar: {
        width: '100%',
        marginLeft: '15px'
    },
    btnContainer: {
        marginTop: '15px',
        marginLeft: '15px',
    },
    loader: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: '#7878bb47',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0
    },
    welcomeTxt: {
        margin: 0,
        marginLeft: '10px',
        marginTop: '10px',
        fontFamily: 'roboto',
        fontSize: '16px',
        fontWeight: 400
    },
    welcomeTxtContainer: {
        marginBottom: '5px'
    }
}));

const CognitoReport = (props) => {
    var innoOrganization = Buffer.from(`INNOVATION;${new Date()}`).toString('base64')
    const classes = useStyles();
    //const baseURL = 'https://dev.fabric.carynhealth.com/api/v1/';
    const [baseURL, setbaseURL] = useState('');
    const [userPoolList, setUserPool] = useState([]);
    const [selectedEnv, setSelectedEnv] = useState(-1);
    const [selectedEnvvalue, setSelectedEnvValue] = useState(-1);
    const [selectedUserPoolIndex, setUserPoolIndx] = useState(-1);
    const [userName, setUserName] = useState('');
    const [showLoader, toggleLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [isError, setError] = useState(false)

    useEffect(() => {

        getUserPool()



    }, []);

    const getUserPool = () => {
        toggleLoader(true)
        //let baseURL=process.env.REACT_APP_BASE_URL

        // login()
        //     .then((token) => {
        //         console.log(token)

        //     });

        Axios.get(process.env.REACT_APP_BASE_URL + 'v1/csrportal/userpool/all',{
            headers: {
                'X-Tenant-Id': innoOrganization,
                Authorization: 'Bearer ' + localStorage.getItem('innobearerToken')
            }
        }).then((resp) => {
            console.log(resp.data)
            let list = formatResponse(resp.data);
            if (list.length > 0) {
                setUserPool(list);
                setSelectedEnv(0);
                setUserPoolIndx(0)
            }
            toggleLoader(false)
        }).catch((err) => {
            toggleLoader(false)
        });;
    }



    const formatResponse = (response) => {
        let list = []
        Object.keys(response).forEach((key) => {
            list.push({ env: key, list: response[key] })
        });
        console.log(list)
        return list;
    }

    const changeEnv = (event) => {
        console.log(event.target.value)
        setSelectedEnv(event.target.value);
        setUserPoolIndx(0);
        setMessage('');
    }

    const changeUserPool = (event) => {
        console.log(event.target.value)
        setUserPoolIndx(event.target.value)
        setMessage('');
    }

    const submitForm = (event) => {

        event.preventDefault();
        let userpoolId = userPoolList[selectedEnv].list[selectedUserPoolIndex].userpoolId;
        let username = userName
        console.log(userpoolId, username);
        setMessage('');
        downloadReport(userpoolId, username)
    }

    const downloadReport = (userpoolId, username) => {
        console.log('selected environment :' + userPoolList[selectedEnv].env)
        let currentEnv = userPoolList[selectedEnv].env;
        let request = {
            "cognitoUserPool": userpoolId,
            "username": username
        }
        toggleLoader(true)
        let baseURL = null
        //based on selected environment switch api between dev and prod.AWS configs are API level
        if (currentEnv.toLowerCase() != 'development') {
            baseURL = 'https://testfabric.carynhealth.com/api/v1/';
        }
        else {
            baseURL = 'https://dev.fabric.carynhealth.com/api/v1/';
            //baseURL='http://localhost:8085/v1/';
        }
        let innoOrganization = Buffer.from(`INNOVATION;${new Date()}`).toString('base64')
        
        Axios({
            url: baseURL + 'memberportal/cognitoreport/excel?userpool=' + userpoolId,
            method: 'GET',
            responseType: 'blob',
            headers: {'X-Tenant-Id': innoOrganization, Authorization:  'Bearer ' + localStorage.getItem('innobearerToken') } // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', userpoolId + '.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            toggleLoader(false)

        }).catch((err) => {
            console.log(err);
            alert('Please try again!')
            toggleLoader(false)
        });


    }


    return (

        <Container maxWidth="lg" style={{ background: 'white', marginTop: '0px', minHeight: '250px', padding: '0', position: 'relative' }}>

            <form onSubmit={submitForm} className="user-mgmt-form" style={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                
                <div style={{width: '440px', margin:'10px'}}>
                        <FormControl>
                            <InputLabel shrink htmlFor="age-native-simple">Environment</InputLabel>
                            <Select
                                native
                                onChange={changeEnv}
                                style={customStyle.myCustomDropdown}
                            >
                                {
                                    userPoolList.map(((item, index) => {
                                        return <option key={item.env} value={index}>{item.env}</option>
                                    }))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{width: '440px', margin:'10px'}}>
                        <FormControl>
                            <InputLabel shrink htmlFor="age-native-simple">User Pool:</InputLabel>
                            <Select
                                native
                                onChange={changeUserPool}
                                style={customStyle.myCustomDropdown}
                            >
                                {
                                    userPoolList[selectedEnv] && userPoolList[selectedEnv].list.map((userpool, index) => {
                                        return <option key={userpool.userpoolId} value={index}>{userpool.userpoolName}</option>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <FormControl className={classes.btnContainer} >
                            <Button size="small" color="primary" variant="contained"
                                type="submit"
                            //  onClick={resendEmail} 
                            //disabled={!isSearchBtnEnabled}
                            >Download Report</Button>
                        </FormControl>
                    </div>
            </form>
            <div style={{
                padding: '10px 35px',
                background: isError ? 'rgb(208 53 53)' : 'rgb(30 144 49)',
                color: 'white',
                margin: '10px 30px',
                display: message.length > 0 ? 'block' : 'none'
            }}>
                <p>{message}</p>
            </div>
            <div className={classes.loader} style={{
                display: showLoader ? 'flex' : 'none'
            }}>
                <CircularProgress />
            </div>
        </Container>
    );
}
export default CognitoReport