import React, { Component } from "react";
import MUIDataTable from "mui-datatables";
import configurations from "../../configurations";
import {
  createTheme,
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import { isEmpty, includes, keysIn, groupBy } from "lodash";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import HomeIcon from "@material-ui/icons/Home";
import customStyleUHS from "../../Assets/CSS/stylesheet";
import Loader from "../Commonscreens/loader";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import { Modal } from "react-bootstrap";
import customcss from "./CSS/stylesheet_UHS";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import CommonDropDwn from "./CommonDropDwn_1";
import customStyle from "./styleSheet";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CloseIcon from "@material-ui/icons/Close";
import {
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";
import Fab from "@material-ui/core/Fab";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import axios from "axios";
import Chip from "@material-ui/core/Chip";
import { maxWidth } from "@material-ui/system";
import TransactionTable from "./TransactionTable";

const CrudButton = withStyles(customStyleUHS.crudBtnAgent)(Fab);

const AntTabs = withStyles(customStyle.tabs)(Tabs);
const AntTab = withStyles((theme) => customStyle.tab)((props) => (
  <Tab disableRipple {...props} />
));

var prospectFormData = new FormData();
prospectFormData.append("draw", "1");
prospectFormData.append("start", "0");
prospectFormData.append("length", "10");
prospectFormData.append("columns[0].data", "id");
prospectFormData.append("columns[0].name", "");
prospectFormData.append("columns[0].searchable", "true");
prospectFormData.append("columns[0].orderable", "true");
prospectFormData.append("columns[0].search.regex", "false");
prospectFormData.append("columns[0].search.value", "");

prospectFormData.append("columns[1].data", "createdDate");
prospectFormData.append("columns[1].name", "");
prospectFormData.append("columns[1].searchable", "true");
prospectFormData.append("columns[1].orderable", "true");
prospectFormData.append("columns[1].search.regex", "false");
prospectFormData.append("columns[1].search.value", "");

prospectFormData.append("columns[2].data", "email");
prospectFormData.append("columns[2].name", "");
prospectFormData.append("columns[2].searchable", "true");
prospectFormData.append("columns[2].orderable", "true");
prospectFormData.append("columns[2].search.regex", "false");
prospectFormData.append("columns[2].search.value", "");

prospectFormData.append("columns[3].data", "firstName");
prospectFormData.append("columns[3].name", "");
prospectFormData.append("columns[3].searchable", "true");
prospectFormData.append("columns[3].orderable", "true");
prospectFormData.append("columns[3].search.regex", "false");
prospectFormData.append("columns[3].search.value", "");

prospectFormData.append("columns[4].data", "lastName");
prospectFormData.append("columns[4].name", "");
prospectFormData.append("columns[4].searchable", "true");
prospectFormData.append("columns[4].orderable", "true");
prospectFormData.append("columns[4].search.regex", "false");
prospectFormData.append("columns[4].search.value", "");

prospectFormData.append("columns[5].data", "completionStatus");
prospectFormData.append("columns[5].name", "");
prospectFormData.append("columns[5].searchable", "true");
prospectFormData.append("columns[5].orderable", "false");
prospectFormData.append("columns[5].search.regex", "false");
prospectFormData.append("columns[5].search.value", "");

prospectFormData.append("columns[6].data", "empid");
prospectFormData.append("columns[6].name", "");
prospectFormData.append("columns[6].searchable", "true");
prospectFormData.append("columns[6].orderable", "false");
prospectFormData.append("columns[6].search.regex", "false");
prospectFormData.append("columns[6].search.value", "NULL");

prospectFormData.append("columns[7].data", "record");
prospectFormData.append("columns[7].name", "");
prospectFormData.append("columns[7].searchable", "true");
prospectFormData.append("columns[7].orderable", "true");
prospectFormData.append("columns[7].search.regex", "false");
prospectFormData.append("columns[7].search.value", "");

prospectFormData.append("columns[8].data", "memberName");
prospectFormData.append("columns[8].name", "");
prospectFormData.append("columns[8].searchable", "true");
prospectFormData.append("columns[8].orderable", "true");
prospectFormData.append("columns[8].search.regex", "false");
prospectFormData.append("columns[8].search.value", "");

prospectFormData.append("columns[9].data", "enrollFlag");
prospectFormData.append("columns[9].name", "");
prospectFormData.append("columns[9].searchable", "true");
prospectFormData.append("columns[9].orderable", "false");
prospectFormData.append("columns[9].search.regex", "false");
prospectFormData.append("columns[9].search.value", "");

prospectFormData.append("columns[10].data", "clientId");
prospectFormData.append("columns[10].name", "");
prospectFormData.append("columns[10].searchable", "true");
prospectFormData.append("columns[10].orderable", "true");
prospectFormData.append("columns[10].search.regex", "false");
prospectFormData.append("columns[10].search.value", "");

prospectFormData.append("order[0].column", "0");
prospectFormData.append("order[0].dir", "desc");
prospectFormData.append("search.regex", "false");
prospectFormData.append("search.value", "");

let prospectRequestOptions = {
  method: "POST",
  body: prospectFormData,
};

var formData = new FormData();
formData.append("draw", "1");
formData.append("start", "0");
formData.append("length", "10");

formData.append("columns[0].data", "id");
formData.append("columns[0].name", "");
formData.append("columns[0].searchable", "true");
formData.append("columns[0].orderable", "true");
formData.append("columns[0].search.regex", "false");
formData.append("columns[0].search.value", "");

formData.append("columns[1].data", "user");
formData.append("columns[1].name", "");
formData.append("columns[1].searchable", "true");
formData.append("columns[1].orderable", "true");
formData.append("columns[1].search.regex", "false");
formData.append("columns[1].search.value", "");

formData.append("columns[2].data", "createdDate");
formData.append("columns[2].name", "");
formData.append("columns[2].searchable", "true");
formData.append("columns[2].orderable", "true");
formData.append("columns[2].search.regex", "false");
formData.append("columns[2].search.value", "");

formData.append("columns[3].data", "eventName");
formData.append("columns[3].name", "");
formData.append("columns[3].searchable", "true");
formData.append("columns[3].orderable", "true");
formData.append("columns[3].search.regex", "false");
formData.append("columns[3].search.value", "");

formData.append("columns[4].data", "eventType");
formData.append("columns[4].name", "");
formData.append("columns[4].searchable", "true");
formData.append("columns[4].orderable", "true");
formData.append("columns[4].search.regex", "false");
formData.append("columns[4].search.value", "");

formData.append("columns[5].data", "eventData");
formData.append("columns[5].name", "");
formData.append("columns[5].searchable", "true");
formData.append("columns[5].orderable", "true");
formData.append("columns[5].search.regex", "false");
formData.append("columns[5].search.value", "");

formData.append("columns[6].data", "referenceTransactionId");
formData.append("columns[6].name", "");
formData.append("columns[6].searchable", "true");
formData.append("columns[6].orderable", "true");
formData.append("columns[6].search.regex", "false");
formData.append("columns[6].search.value", "");

formData.append("columns[7].data", "response");
formData.append("columns[7].name", "");
formData.append("columns[7].searchable", "true");
formData.append("columns[7].orderable", "true");
formData.append("columns[7].search.regex", "false");
formData.append("columns[7].search.value", "");

formData.append("columns[8].data", "newTransactionId");
formData.append("columns[8].name", "");
formData.append("columns[8].searchable", "true");
formData.append("columns[8].orderable", "true");
formData.append("columns[8].search.regex", "false");
formData.append("columns[8].search.value", "");

formData.append("columns[9].data", "status");
formData.append("columns[9].name", "");
formData.append("columns[9].searchable", "true");
formData.append("columns[9].orderable", "true");
formData.append("columns[9].search.regex", "false");
formData.append("columns[9].search.value", "");

formData.append("order[0].column", "0");
formData.append("order[0].dir", "desc");
formData.append("search.regex", "false");
formData.append("search.value", "");

let requestOptions = {
  method: "POST",
  body: formData,
};

var agentFormData = new FormData();
agentFormData.append("draw", "1");
agentFormData.append("start", "0");
agentFormData.append("length", "10");
agentFormData.append("columns[0].data", "id");
agentFormData.append("columns[0].name", "");
agentFormData.append("columns[0].searchable", "true");
agentFormData.append("columns[0].orderable", "true");
agentFormData.append("columns[0].search.regex", "false");
agentFormData.append("columns[0].search.value", "");

// agentFormData.append("columns[1].data", "createdDate");
// agentFormData.append("columns[1].name", "");
// agentFormData.append("columns[1].searchable", "true");
// agentFormData.append("columns[1].orderable", "true");
// agentFormData.append("columns[1].search.regex", "false");
// agentFormData.append("columns[1].search.value", "");

// agentFormData.append("columns[2].data", "firstName");
// agentFormData.append("columns[2].name", "");
// agentFormData.append("columns[2].searchable", "true");
// agentFormData.append("columns[2].orderable", "true");
// agentFormData.append("columns[2].search.regex", "false");
// agentFormData.append("columns[2].search.value", "");

// agentFormData.append("columns[3].data", "lastName");
// agentFormData.append("columns[3].name", "");
// agentFormData.append("columns[3].searchable", "true");
// agentFormData.append("columns[3].orderable", "true");
// agentFormData.append("columns[3].search.regex", "false");
// agentFormData.append("columns[3].search.value", "");

agentFormData.append("columns[1].data", "id");
agentFormData.append("columns[1].name", "");
agentFormData.append("columns[1].searchable", "true");
agentFormData.append("columns[1].orderable", "true");
agentFormData.append("columns[1].search.regex", "false");
agentFormData.append("columns[1].search.value", "");

agentFormData.append("columns[2].data", "email");
agentFormData.append("columns[2].name", "");
agentFormData.append("columns[2].searchable", "true");
agentFormData.append("columns[2].orderable", "true");
agentFormData.append("columns[2].search.regex", "false");
agentFormData.append("columns[2].search.value", "");

agentFormData.append("columns[3].data", "phone");
agentFormData.append("columns[3].name", "");
agentFormData.append("columns[3].searchable", "true");
agentFormData.append("columns[3].orderable", "true");
agentFormData.append("columns[3].search.regex", "false");
agentFormData.append("columns[3].search.value", "");

agentFormData.append("columns[4].data", "clientId");
agentFormData.append("columns[4].name", "");
agentFormData.append("columns[4].searchable", "true");
agentFormData.append("columns[4].orderable", "true");
agentFormData.append("columns[4].search.regex", "false");
agentFormData.append("columns[4].search.value", "");

agentFormData.append("columns[5].data", "brokerId");
agentFormData.append("columns[5].name", "");
agentFormData.append("columns[5].searchable", "true");
agentFormData.append("columns[5].orderable", "true");
agentFormData.append("columns[5].search.regex", "false");
agentFormData.append("columns[5].search.value", "");

agentFormData.append("columns[6].data", "agentName");
agentFormData.append("columns[6].name", "");
agentFormData.append("columns[6].searchable", "true");
agentFormData.append("columns[6].orderable", "true");
agentFormData.append("columns[6].search.regex", "false");
agentFormData.append("columns[6].search.value", "");

agentFormData.append("columns[7].data", "associationId");
agentFormData.append("columns[7].name", "");
agentFormData.append("columns[7].searchable", "true");
agentFormData.append("columns[7].orderable", "true");
agentFormData.append("columns[7].search.regex", "false");
agentFormData.append("columns[7].search.value", "");

agentFormData.append("columns[8].data", "totalCollection");
agentFormData.append("columns[8].name", "");
agentFormData.append("columns[8].searchable", "true");
agentFormData.append("columns[8].orderable", "true");
agentFormData.append("columns[8].search.regex", "false");
agentFormData.append("columns[8].search.value", "");

agentFormData.append("order[0].column", "0");
agentFormData.append("order[0].dir", "desc");
agentFormData.append("search.regex", "false");
agentFormData.append("search.value", "");

let agentRequestOptions = {
  method: "POST",
  body: agentFormData,
};

const GenBtn = withStyles(customStyleUHS.genrateBtn)(Button);

let theData = [];
const LunchAccess = ["niting@perscitussln.com", "devadmin@yopmail.com"];
class AgentMemberSearch extends Component {
  state = {
    loaderShow: false,
    data: [],
    count: 10,
    rowsPerPage: 10,
    searchTypeValue: "",
    searchShow: true,
    searchText: "",
    statusValue: "",
    targetDate: null,
    birthDtFocus: false,
    selctAgentOrProspect: "0",
    agentList: null,
    selectedAgent: null,
    selectedMemberId: null,
    channelids: null,
    selectedAgent: "",
    flag: "",
    detailsData: {},
    activeTab: 0,
    idCardURL: null,
  };

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        if (response && response.data) {
          let data = response.data;
          console.log(data);
          this.setState({
            countryCode: data.country_calling_code,
          });
          this.toggleLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  componentDidMount() {
    this.getGeoInfo();
    this.setState({
      loaderShow: true,
    });
    var ids = [];
    axios
      .get(configurations.agentBaseUrl + "/enrollment/getAllClient")
      .then((res) => {
        res.data.response.map((data) => {
          ids.push(data.clientId);
        });
        console.log();
        axios
          .get(
            configurations.agentBaseUrl +
              "/enrollment/getClientByClientIds/" +
              ids.toString()
          )
          .then((response) => {
            // console.log("requestOptions", response.data.response)
            this.setState({ channelids: response.data.response });
          });
      });

    // this.getDetails(requestOptions);
    this.getAgentsDetails(agentRequestOptions);
  }

  getDetails = (requestOptions) => {
    //    console.log("requestOptions:::",requestOptions);
    agentFormData.set("start", "0");
    agentFormData.set("length", this.state.rowsPerPage.toString());

    fetch(
      configurations.auditBaseUrl + "/transaction/getAuditLogs/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("-------------------Data----------------------");

        let res = result.response.data.map((data) => {
          return data.response;
        });
        // let stat=res.map(st=>{return st.result.map(dd=>{return dd})})
        let stat = Object.keys(res).map((k, i) => res[k]);
        // console.log(stat)
        if (result.response && result.response.data) {
          this.setState({
            data: result.response.data,
            count: result.response.recordsFiltered,
            loaderShow: false,
          });
        }
      });
  };

  getAgentsDetails = (agentRequestOptions) => {
    // console.log("requestOptions:::",agentRequestOptions);
    agentFormData.set("start", "0");
    agentFormData.set("columns[2].search.value", "");
    agentFormData.set("columns[4].search.value", "");
    agentFormData.set("columns[5].search.value", "");
    agentFormData.set("columns[6].search.value", "");
    agentFormData.set("length", this.state.rowsPerPage.toString());

    fetch(
      configurations.agentBaseUrl + "/agentlogin/getAgentListForAgency",
      agentRequestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("-------------------Data----------------------");

        let res = result.response.data.map((data) => {
          return data.response;
        });
        // let stat=res.map(st=>{return st.result.map(dd=>{return dd})})
        let stat = Object.keys(res).map((k, i) => res[k]);
        //  console.log(stat)
        if (result.response && result.response.data) {
          var data = result.response.data;
          for (var i = 0; i < data.length; i++) {
            if (data[i].totalCollection === null) {
              data[i].totalCollection = 0;
            }
          }
          this.setState({
            data: data,
            count: result.response.recordsFiltered,
            loaderShow: false,
          });
        }
      });
  };

  getMuiThemeTran = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            padding: "8px",
          },
        },
        MUIDataTable: {
          root: {
            backgroundColor: "#FF000",
          },
          paper: {
            boxShadow: "none",
          },
        },

        MuiTableRow: {
          root: {
            "&$selected": {
              backgroundColor: "#ebf2ff",
            },
          },
        },
      },
    });
  getProspectDetails = (prospectRequestOptions) => {
    prospectFormData.set("start", "0");
    prospectFormData.set("columns[2].search.value", "");
    prospectFormData.set("start", "0");
    prospectFormData.set("columns[4].search.value", "");
    prospectFormData.set("columns[5].search.value", "");
    prospectFormData.set("columns[8].search.value", "");
    prospectFormData.set("columns[5].search.value", "");
    prospectFormData.set("columns[9].search.value", "");
    prospectFormData.set("columns[10].search.value", "");
    prospectFormData.set("length", this.state.rowsPerPage.toString());

    fetch(
      configurations.agentBaseUrl + "/enrollment/getMember",
      prospectRequestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let res = result.response.data.map((data) => {
          return data.response;
        });
        let stat = Object.keys(res).map((k, i) => res[k]);
        if (result.response && result.response.data) {
          this.setState({
            data: result.response.data,
            count: result.response.recordsFiltered,
            loaderShow: false,
          });
        }
      });
  };
  setAgent = (value, isValid, parentDetails) => {
    if (isValid) {
      this.state[parentDetails.name] = value;
    } else {
      this.state[parentDetails.name] = "";
    }

    this.setState({
      refresh: true,
    });
  };
  submitReAssignAgent = (flag) => {
    if (flag) {
      let dataObj = {
        memberId: this.state.selectedMemberId,
        brokerId: this.state.selectedAgent,
      };

      let findAgent = this.state.agentList.find(
        (obj) => obj.value === this.state.selectedAgent
      );

      if (findAgent) {
        this.setState({ selectedAgentName: findAgent.key.split("(")[0] });
      }

      axios
        .post(
          configurations.agentBaseUrl + "/agentlogin/reassignProspect",
          dataObj
        )
        .then((response) => {
          console.log("======== submitReAssignAgent ========");
          console.log(this.state.selectedAgent);
          if (response.data.code === 200) {
            this.setState({
              loaderShow: false,
              agentList: [],
              selectedAgent: "",
              reAssignAgentModal: false,
              reAssign: true,
              msgModal: true,
              // errMsg: "Re-assigned Successfully Done"
            });

            this.refreshTable();
          }
        });
    } else {
      // this.setState({ loaderShow: true,})
      axios
        .get(
          process.env.REACT_APP_agentBaseUrl +
            "/agentlogin/deleteUser/" +
            this.state.selectedMemberId
        )
        .then((response) => {
          this.setState(
            {
              loaderShow: false,
              agentList: [],
              selectedAgent: "",
              reAssignAgentModal: false,
              reAssign: true,
              msgModal: true,
              selectedMemberId: "",
              flag: "",
              // errMsg: "Re-assigned Successfully Done"
            },
            () => {
              this.refreshTable();
            }
          );
        });
    }
  };
  // ======================Filter code=============================

  handleFilterSubmit = (applyFilters) => {
    let filterList = applyFilters();
    console.log("===============");
    console.log(filterList);

    for (let i = 0; i < filterList.length; i++) {
      console.log("===============");
      console.log(filterList[0][i]);

      if (i == 0) {
        let strDate = filterList[i][0];
        if (strDate !== null && strDate !== "" && strDate !== undefined) {
          console.log("strdate::", strDate);
          strDate = moment(filterList[i][0], "MMMM DD, YYYY").format(
            "YYYY-MM-DD"
          );
          console.log("strdate::", strDate);
          formData.set("columns[2].search.value", strDate);
        } else {
          console.log("strdate::", strDate);
          formData.set("columns[2].search.value", "");
          // }
        }
      }
    }
    this.getDetails(requestOptions);
  };

  // ==========================Search==============================

  handleChageSearchType(event) {
    let value = event.target.value;

    if (value === "" || value === null) {
      this.setState(
        { searchShow: true, searchText: "", statusValue: "" },
        () => {
          this.refreshTable();
        }
      );
    } else {
      this.refreshTable();
      // if(this.state.searchTypeValue === '0'){
      //     formData.set("columns[1].search.value","");
      // } else if(this.state.searchTypeValue === '1'){
      //     formData.set("columns[9].search.value","");
      // } else if(this.state.searchTypeValue === '2'){
      //     formData.set("columns[2].search.value","");
      // }
      // this.getAgentsDetails();
      this.setState(
        {
          searchTypeValue: value,
          searchShow: false,
          searchValue: "",
          searchText: "",
        },
        () => {
          console.log("============== searchText ===============");
          console.log(this.state.searchText);
          // this.getAgentsDetails();
        }
      );
    }
  }
  handleChangeType(event) {
    let value = event.target.value;
    this.refreshTable();
    this.setState({
      searchTypeValue: value,
      searchShow: false,
      searchValue: "",
      searchText: "",
    });
  }

  handleselctAgentOrProspect = (event) => {
    this.setState({
      loaderShow: true,
      searchTypeValue: "",
      searchText: "",
    });
    let value = event.target.value;
    this.setState({ selctAgentOrProspect: value }, () => {
      if (value === "0" || value === 0) {
        this.getAgentsDetails(agentRequestOptions);
      } else {
        this.getProspectDetails(prospectRequestOptions);
      }
    });
  };

  // ===========================================================================================

  searchRecord = () => {
    this.setState({
      loaderShow: true,
    });
    if (this.state.searchTypeValue === "0") {
      if (this.state.selctAgentOrProspect == "0") {
        //-------------name search ----------------
        this.state.targetDate = null;
        agentFormData.set("start", "0");
        agentFormData.set("columns[2].search.value", this.state.searchText);
        agentFormData.set("columns[4].search.value", "");
        agentFormData.set("columns[5].search.value", "");
        agentFormData.set("columns[6].search.value", "");
        fetch(
          configurations.agentBaseUrl + "/agentlogin/getAgentListForAgency",
          agentRequestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.response && data.response.data) {
              var dataKey = data.response.data;
              for (var i = 0; i < dataKey.length; i++) {
                if (dataKey[i].totalCollection === null) {
                  dataKey[i].totalCollection = 0;
                }
              }
              this.setState({
                data: dataKey,
                count: data.response.recordsFiltered,
                targateDate: null,
                statusValue: "",
                loaderShow: false,
              });
            } else {
              this.setState({ loaderShow: false });
            }
          });
      } else {
        this.state.targetDate = null;
        prospectFormData.set("start", "0");
        prospectFormData.set("columns[2].search.value", this.state.searchText);
        prospectFormData.set("columns[4].search.value", "");
        prospectFormData.set("columns[5].search.value", "");
        prospectFormData.set("columns[6].search.value", "");
        prospectFormData.set("columns[10].search.value", "");
        fetch(
          configurations.agentBaseUrl + "/enrollment/getMember",
          prospectRequestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.response && data.response.data) {
              this.setState({
                data: data.response.data,
                count: data.response.recordsFiltered,
                targateDate: null,
                statusValue: "",
                loaderShow: false,
              });
            } else {
              this.setState({ loaderShow: false });
            }
          });
      }
    }
    if (this.state.searchTypeValue === "1") {
      if (this.state.selctAgentOrProspect == "0") {
        this.state.targetDate = null; //-----------------status search-----------------
        agentFormData.set("start", "0");
        agentFormData.set("columns[2].search.value", "");
        agentFormData.set("columns[4].search.value", "");
        agentFormData.set("columns[5].search.value", this.state.searchText);
        agentFormData.set("columns[6].search.value", "");
        fetch(
          configurations.agentBaseUrl + "/agentlogin/getAgentListForAgency",
          agentRequestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.response && data.response.data) {
              var dataKey = data.response.data;
              for (var i = 0; i < dataKey.length; i++) {
                if (dataKey[i].totalCollection === null) {
                  dataKey[i].totalCollection = 0;
                }
              }
              this.setState({
                data: dataKey,
                count: data.response.recordsFiltered,
                loaderShow: false,
              });
            } else {
              this.setState({ loaderShow: false });
            }
          });
      } else {
        this.state.targetDate = null; //-----------------status search-----------------
        prospectFormData.set("start", "0");
        prospectFormData.set("columns[2].search.value", "");
        prospectFormData.set("columns[4].search.value", "");
        prospectFormData.set("columns[8].search.value", this.state.searchText);
        prospectFormData.set("columns[6].search.value", "");
        prospectFormData.set("columns[5].search.value", "");
        prospectFormData.set("columns[10].search.value", "");
        fetch(
          configurations.agentBaseUrl + "/enrollment/getMember",
          prospectRequestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.response && data.response.data) {
              this.setState({
                data: data.response.data,
                count: data.response.recordsFiltered,
                loaderShow: false,
              });
            } else {
              this.setState({ loaderShow: false });
            }
          });
      }
    }
    if (this.state.searchTypeValue === "2") {
      if (this.state.selctAgentOrProspect == "0") {
        //-------------Date search--------------------
        agentFormData.set("start", "0");
        agentFormData.set("columns[2].search.value", "");
        agentFormData.set("columns[5].search.value", "");
        agentFormData.set("columns[4].search.value", this.state.searchText);
        agentFormData.set("columns[6].search.value", "");
        fetch(
          configurations.agentBaseUrl + "/agentlogin/getAgentListForAgency",
          agentRequestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.response && data.response.data) {
              var dataKey = data.response.data;
              for (var i = 0; i < dataKey.length; i++) {
                if (dataKey[i].totalCollection === null) {
                  dataKey[i].totalCollection = 0;
                }
              }
              this.setState({
                data: dataKey,
                count: data.response.recordsFiltered,
                statusValue: "",
                loaderShow: false,
              });
            } else {
              this.setState({ loaderShow: false });
            }
          });
      } else {
        this.state.targetDate = null; //-----------------status search-----------------
        prospectFormData.set("start", "0");
        prospectFormData.set("columns[2].search.value", "");
        prospectFormData.set("columns[4].search.value", "");
        prospectFormData.set("columns[8].search.value", "");
        prospectFormData.set("columns[6].search.value", "");
        prospectFormData.set("columns[10].search.value", "");
        if (this.state.searchText == "9") {
          prospectFormData.set("columns[9].search.value", "true");
          prospectFormData.set("columns[5].search.value", "");
        } else {
          prospectFormData.set("columns[9].search.value", "false");
          prospectFormData.set(
            "columns[5].search.value",
            this.state.searchText
          );
        }
        fetch(
          configurations.agentBaseUrl + "/enrollment/getMember",
          prospectRequestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.response && data.response.data) {
              this.setState({
                data: data.response.data,
                count: data.response.recordsFiltered,
                loaderShow: false,
              });
            } else {
              this.setState({ loaderShow: false });
            }
          });
      }
    }
    if (this.state.searchTypeValue === "3") {
      if (this.state.selctAgentOrProspect == "0") {
        agentFormData.set("start", "0");
        agentFormData.set("columns[2].search.value", "");
        agentFormData.set("columns[5].search.value", "");
        agentFormData.set("columns[4].search.value", "");
        agentFormData.set("columns[6].search.value", this.state.searchText);
        fetch(
          configurations.agentBaseUrl + "/agentlogin/getAgentListForAgency",
          agentRequestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.response && data.response.data) {
              var dataKey = data.response.data;
              for (var i = 0; i < dataKey.length; i++) {
                if (dataKey[i].totalCollection === null) {
                  dataKey[i].totalCollection = 0;
                }
              }
              this.setState({
                data: data.response.data,
                count: data.response.recordsFiltered,
                statusValue: "",
                loaderShow: false,
              });
            } else {
              this.setState({ loaderShow: false });
            }
          });
      } else {
        this.state.targetDate = null;
        prospectFormData.set("start", "0");
        prospectFormData.set("columns[2].search.value", "");
        prospectFormData.set("columns[4].search.value", "");
        prospectFormData.set("columns[8].search.value", "");
        prospectFormData.set("columns[6].search.value", "");
        prospectFormData.set("columns[5].search.value", "");
        prospectFormData.set("columns[10].search.value", this.state.searchText);
        fetch(
          configurations.agentBaseUrl + "/enrollment/getMember",
          prospectRequestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.response && data.response.data) {
              this.setState({
                data: data.response.data,
                count: data.response.recordsFiltered,
                loaderShow: false,
              });
            } else {
              this.setState({ loaderShow: false });
            }
          });
      }
    }
  };

  setSearch(event) {
    let value = event.target.value;
    this.setState({
      searchText: value,
    });
    if (value == "") {
      this.refreshTable();
    }
  }

  statusChange = (event) => {
    console.log(event.target.value);
    this.setState({
      statusValue: event.target.value,
      searchText: event.target.value,
    });
  };

  handleDate(event) {
    this.setState({
      targetDate:
        moment().format("YYYY") +
        "-" +
        moment(event).format("MM") +
        "-" +
        moment(event).format("DD"),
      searchText:
        moment(event).format("YYYY") +
        "-" +
        moment(event).format("MM") +
        "-" +
        moment(event).format("DD"),
    });
  }

  //   ==============Change page =====================

  changePage = (page, rows) => {
    this.setState({
      loaderShow: true,
    });

    if (page !== undefined && rows && this.state.selctAgentOrProspect == "0") {
      let nextPage = page * rows;
      agentFormData.set("start", nextPage.toString());
      agentFormData.set("length", rows.toString());
      fetch(
        configurations.agentBaseUrl + "/agentlogin/getAgentListForAgency",
        agentRequestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.response && data.response.data) {
            this.setState({
              data: data.response.data,
              rowsPerPage: rows,
              loaderShow: false,
            });
          } else {
            this.setState({ loaderShow: false });
          }
        });
    } else if (page !== undefined && rows) {
      let nextPage = page * rows;
      prospectFormData.set("start", nextPage.toString());
      prospectFormData.set("length", rows.toString());
      fetch(
        configurations.agentBaseUrl + "/enrollment/getMember",
        prospectRequestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.response && data.response.data) {
            this.setState({
              data: data.response.data,
              rowsPerPage: rows,
              loaderShow: false,
            });
          } else {
            this.setState({ loaderShow: false });
          }
        });
    }
  };

  // =============== Table Theme========================

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            // paddingLeft: '7px ',
            paddingBottom: "10px",
            //    paddingRight:'23px',
            paddingTop: "10px",
            fontSize: "14px",
            color: "#333333",
            width: "max-content",
          },
          responsiveBase: {
            // root:{
            padding: "30px",
            // }
          },
          emptyTitle: {
            textAlign: "center",
            // ,width:'0px',
            marginLeft: "450px",
          },
        },
        MUIDataTableBody: {
          emptyTitle: {
            textAlign: "center",
            marginLeft: "450px",
          },
        },
        MuiTableRow: {
          root: {
            "&$selected": {
              backgroundColor: "#ebf2ff",
            },
          },
        },
        MUIDataTable: { responsiveBase: { margin: "23px" } },
        MuiSvgIcon: {
          root: {
            height: "35px",
            width: "35px",
          },
        },
        MuiIconButton: {
          root: {
            height: "20px",
            width: "20px",
          },
        },
        MuiTypography: {
          h6: {
            fontWeight: "bold",
            marginBottom: "-28px",
            marginLeft: "-70%",
            marginRight: "50px",
          },
        },
        MuiToolbar: {
          gutters: { paddingLeft: "5px" },
          regular: { minHeight: "0px", alignItems: "baseline" },
        },
        MuiTableCell: {
          root: { borderBottom: "1px solid rgb(0,0,0)", padding: "8px" },
          footer: { borderBottom: "none" },
        },
        MuiDataTablePagination: { borderBottom: "none" },
        MUIDataTableHeadCell: {
          root: {
            background: "blue",
            fontWeight: "bold",
            paddingLeft: "10px",
            padding: "0px",
          },
          MUIDataTable: {
            responsiveScroll: {
              maxHeight: "380px",
            },
          },
        },
      },
    });

  handleBack = () => {
    this.setState({
      loaderShow: true,
      searchTypeValue: "",
      statusValue: "",
      searchText: "",
    });
    formData.set("start", "0");
    formData.set("columns[2].search.value", "");
    formData.set("columns[9].search.value", "");
    formData.set("columns[1].search.value", "");
    this.props.onClick();
  };
  refreshTable = () => {
    this.setState({
      loaderShow: true,
      searchTypeValue: "",
      statusValue: "",
      searchText: "",
    });
    if (this.state.selctAgentOrProspect == "0") {
      agentFormData.set("start", "0");
      agentFormData.set("columns[5].search.value", "");
      this.getAgentsDetails(agentRequestOptions);
    } else {
      prospectFormData.set("columns[2].search.value", "");
      prospectFormData.set("start", "0");
      prospectFormData.set("columns[4].search.value", "");
      prospectFormData.set("columns[5].search.value", "");
      prospectFormData.set("columns[8].search.value", "");
      prospectFormData.set("columns[10].search.value", "");
      this.getProspectDetails(prospectRequestOptions);
    }
  };

  getUSerDetails = (id, flag, source) => {
    axios
      .get(configurations.agentBaseUrl + "/enrollment/getViewDetails/" + id)
      .then((response) => {
        this.state.detailsData = response.data.response;
        console.log("uuuuuuu ", this.state.data, " ", id);
        if (response.data.code === 200) {
          let obj = [];
          let findLeadData = null;
          if (source == "emp") {
            findLeadData = this.state.employeeData.find((obj) => obj.id === id);
          } else if (source == "houseHold") {
            findLeadData = this.state.profileProspectData.find(
              (obj) => obj.id === id
            );
          } else {
            findLeadData = this.state.data.find((obj) => obj.id === id);
          }

          const userInfo = findLeadData.leadQualificationData;
          console.log("dydydydydydyd ", flag, " ", response.data.response);
          if (flag && response.data.response.flag) {
            obj = [
              {
                key: "Member ID",
                memberIdSource: response.data.response.memberIdSource,
                emailID: response.data.response.email,
                value:
                  response.data.response.memberId != null
                    ? response.data.response.memberId
                    : "N/A",
                type: "STR",
              },
              {
                key: "Name",
                value:this.prefixSuffixMiddleName(
                  response.data.response.prefix,
                  response.data.response.firstName,
                  response.data.response.middleName,
                  response.data.response.lastName,
                  response.data.response.suffix
                ),
                type: "STR",
              },
              {
                key: "Email",
                value: response.data.response.email,
                type: "STR",
              },
              {
                key: "Phone",
                value: response.data.response.phoneNo
                  ? this.state.countryCode +
                    " " +
                    this.formatPhoneNumber(response.data.response.phoneNo)
                  : "NA",
                type: "STR",
              },
              {
                key: "Address",
                value: response.data.response.addressLine1,
                type: "STR",
              },
              {
                key: "City",
                value: response.data.response.city,
                type: "STR",
              },
              {},
              {
                key: "State",
                value: response.data.response.state,
                type: "STR",
              },
              {
                key: "Zip Code",
                value: response.data.response.zipCode,
                type: "STR",
              },
              {
                key: "Plan Purchased",
                value: response.data.response.planName,
                type: "STR",
              },
              {
                key: "Active Date",
                value: moment
                  .utc(response.data.response.benefits[0].benefitBegin)
                  .format("MMMM DD, YYYY"),
                type: "STR",
              },
              {
                key: "Paid through date",
                value: response.data.response.benefits[0].premiumPaidDate
                  ? moment
                      .utc(response.data.response.benefits[0].premiumPaidDate)
                      .format("MMMM DD, YYYY")
                  : "N/A",
                type: "STR",
              },
              {
                key: "Status",
                value: response.data.response.status,
                type: "STR",
              } /*,{
                            key : 'Cost Of Program',
                            value : response.data.response.cost_of_program,
                            type : 'STR'
                        }*/,
              {
                key: "Names of dependents and relationship",
                value: response.data.response.dependents,
                type: "ARR",
              },
              {
                key: "Lead qualification data",
                value: userInfo != null ? userInfo : "N/A",
                type: "STR",
              },
            ];
            this.setState({
              viewUserData: obj,
              loaderShow: false,
              detailsData: {
                memberIdSource: response.data.response.memberIdSource,
                id: id,
                disableFlag: false,
                enrollFlag: flag,
              },
            });
          } else {
            obj = [
              {
                key: "Name",
                value:this.prefixSuffixMiddleName(
                  response.data.response.prefix,
                  response.data.response.firstName,
                  response.data.response.middleName,
                  response.data.response.lastName,
                  response.data.response.suffix
                ),
                type: "STR",
              },
              {
                key: "Email",
                value: response.data.response.email,
                type: "STR",
              },
              {
                key: "Phone",
                value: response.data.response.phone
                  ? this.state.countryCode +
                    " " +
                    this.formatPhoneNumber(response.data.response.phone)
                  : null,
                type: "STR",
              },
              {
                key: "Lead qualification data",
                value: userInfo != null ? userInfo : "N/A",
                type: "STR",
              },
            ];
            this.setState({
              viewUserData: obj,
              loaderShow: false,
              detailsData: {
                memberIdSource: response.data.response.memberIdSource,
                id: id,
                disableFlag: true,
                enrollFlag: flag,
              },
            });
          }
        } else {
          this.setState({
            viewUserData: [],
            loaderShow: false,
            detailsData: {
              memberIdSource: null,
              id: id,
              disableFlag: true,
              enrollFlag: flag,
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          loaderShow: false,
        });
      });
  };
  encryptData = async (request) => {
    let data = {
      memberId: request,
    };
    let response = "";
    let innoOrganization = Buffer.from(`INNOVATION;${new Date()}`).toString(
      "base64"
    );
    await axios
      .post(
        process.env.REACT_APP_BASE_URL + "v1/csrportal/idencryption",
        data,
        {
          headers: {
            "X-Tenant-Id": innoOrganization,
            Authorization: "Bearer " + localStorage.getItem("innobearerToken"),
          },
        }
      )
      .then((resp) => {
        response = encodeURIComponent(resp.data);
      })
      .catch((err) => {
        console.log("Internal server error.");
      });
    return response;
  };
  viewIDcard = async (sourceid, emailID) => {
    this.setState({ loaderShow: true });
    let request = `memberid=${sourceid}&type=""&email=${emailID}`;
    let query = await this.encryptData(request);
    let idURL =
      process.env.REACT_APP_SHARED_MODULE_URL +
      "innovtiveIdCard?query=" +
      query +
      "&token=" +
      localStorage.getItem("innobearerToken");
    this.setState({ idCardURL: idURL, loaderShow: false });
  };
  formatPhoneNumber = (phoneNumberString) => {
    console.log("phoneNumberString", phoneNumberString);
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    console.log("cleaned ", cleaned);
    cleaned = cleaned.slice(-10);
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    console.log("match ", match);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };
  getTransactionDetails = (memberIdSource) => {
    axios
      .get(
        configurations.REACT_APP_transaction_base_url +
          "/transaction/transactionHistory?searchKey=source&searchValue=" +
          memberIdSource
      )
      .then((response) => {
        if (response.data.code === 200) {
          this.setState({
            transactionData: response.data.response,
            loaderShow: false,
            transCount: response.data.response.recordsFiltered,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loaderShow: false,
        });
      });
  };
  getLoginDetails = (memberIdSource) => {
    let obj = {
      username: "maricopa",
      password: "QPvcY0n#S1u",
    };

    axios
      .post(configurations.csrBaseUrl + "login", obj)
      .then((response) => {
        let token = response.headers.authorization;
        axios
          .post(
            configurations.csrBaseUrl + "csrportal/loginhistory",
            {
              memberIdSource: memberIdSource,
            },
            {
              headers: {
                Authorization: `${token}`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.setState({
                loginData: response.data.loginHistory
                  ? response.data.loginHistory
                  : [],
                loaderShow: false,
                loginCount: response.data.loginHistory
                  ? response.data.loginHistory.length
                  : 0,
              });
            }
          })
          .catch(() => {
            this.setState({
              loaderShow: false,
            });
          });
      })
      .catch((err) => {
        this.setState({
          loaderShow: false,
        });
      });
  };
  handleTabChange = (event, newValue) => {
    this.setState({
      loaderShow: true,
    });

    if (newValue === 0) {
      this.setState({
        viewUserData: [],
        transactionData: [],
        loginData: [],
        transCount: 0,
        loginCount: 0,
      });
      this.getUSerDetails(
        this.state.detailsData.id,
        this.state.detailsData.enrollFlag
      );
    } else if (newValue === 1) {
      this.setState({
        viewUserData: [],
        transactionData: [],
        loginData: [],
        transCount: 0,
        loginCount: 0,
      });
      this.getTransactionDetails(this.state.detailsData.memberIdSource);
    } else if (newValue === 2) {
      this.setState({
        viewUserData: [],
        transactionData: [],
        loginData: [],
        transCount: 0,
        loginCount: 0,
      });
      this.getLoginDetails(this.state.detailsData.memberIdSource);
    } else if (newValue === 3) {
      this.setState({
        viewUserData: [],
        transactionData: [],
        loginData: [],
        transCount: 0,
        loginCount: 0,
      });

      this.setState({
        loaderShow: true,
      });
      axios
        .get(
          configurations.agentBaseUrl +
            "/agentlogin/reassignListByMember/1/10/" +
            this.state.selectedMemberId
        )
        .then((response) => {
          console.log("getResignHistory==", response.data.response.reasignData);
          if (
            response.data.response &&
            response.data.response.reasignData.length > 0
          ) {
            this.setState(
              {
                reAssignHistoryData: response.data.response.reasignData,
                // reAssignhistoryModal:true,
                loaderShow: false,
                reAssignHistoryDataFlag: true,
              },
              () =>
                console.log(
                  "getResignHistory==",
                  this.state.reAssignHistoryData
                )
            );
          } else {
            this.setState(
              {
                reAssignHistoryData: [],
                // reAssignhistoryModal:true,
                loaderShow: false,
                reAssignHistoryDataFlag: false,
              },
              () =>
                console.log(
                  "getResignHistory==",
                  this.state.reAssignHistoryData
                )
            );
          }
        });
    }
    this.setState({
      activeTab: newValue,
    });
  };
  lunchMemberPortal = (email) => {
    this.setState({ loaderShow: true });
    let request = {
      memberId: email,
    };
    let innoOrganization = Buffer.from(`INNOVATION;${new Date()}`).toString(
      "base64"
    );
    axios
      .post(
        process.env.REACT_APP_BASE_URL + "v1/csrportal/idencryption",
        request,
        {
          headers: {
            "X-Tenant-Id": innoOrganization,
            Authorization: "Bearer " + localStorage.getItem("innobearerToken"),
          },
        }
      )
      .then((resp) => {
        let encriptedData = encodeURIComponent(resp.data);
        window.open(
          process.env.REACT_APP_MEMBER_PORTAL_URL + "#state=" + encriptedData
        );
        this.setState({ loaderShow: false });
      })
      .catch((err) => {
        this.setState({ loaderShow: false });
        console.log("Internal server error.");
      });
  };
  prefixSuffixMiddleName = (
  prefix,
  firstName,
  middleName,
  lastName,
  suffix
    ) => {
      const displayName = `${prefix ? prefix + " " : ""}${firstName} ${
        middleName ? middleName + " " : ""
      }${lastName}${suffix ? " " + suffix : ""}`;
      return displayName;
    };
  selectMenu = (id, prospectName, flag, dataIndex) => {
    if (flag === "Re-assign") {
      let fullname = "";
      axios
        .get(
          configurations.agentBaseUrl + "/agentlogin/reassignAgentList/" + id
        )
        .then((response) => {
          console.log("======== getAllAgents ========");

          if (response && response.data.response.length > 0) {
            let arr = [];
            response.data.response.forEach((obj) => {
              let getName = obj.firstName + " " + obj.lastName;
              fullname = getName.replace(/\b\w/g, (l) => l.toUpperCase());
              arr.push({
                key: fullname + " (" + obj.brokerId + ") ",
                value: obj.brokerId,
              });
            });

            this.setState({
              loaderShow: false,
              agentList: arr,
              flag: flag,
              selectedMemberId: id,
              prospectFullName: prospectName,
              borokerFullName: fullname,
              reAssignAgentModal: true,
              errMsg: "",
            });
          } else {
            this.setState({
              loaderShow: false,
              reAssignAgentModal: false,
              msgModal: true,
              errMsg: "No agent available to Re-assign",
            });
          }
        });
    } else if (flag === "PushEnrollment") {
      let obj = {
        clientId: this.state.data[dataIndex].clientId,
        associationId: this.state.data[dataIndex].associationId,
        brokerId: this.state.data[dataIndex].brokerId,
        fromMember: true,
        isAdmin: true,
        user_subId: this.state.data[dataIndex].email,
        index: dataIndex,
        empid: this.state.data[dataIndex].empid,
        memberId: this.state.data[dataIndex].id,
        subID: this.state.data[dataIndex].subId, //namita 26 april
        reEnrolledByAgent: false,
        agentEmail: this.state.data[dataIndex].agentEmail,
      };
      let windowReference = window.open();
      axios
        .post(process.env.REACT_APP_agentBaseUrl + "/encrypt/encryptData", obj)
        .then((response) => {
          let url =
            process.env.REACT_APP_ENROLLMENTAPP +
            "/login#state=" +
            response.data.response;
          windowReference.location = url;
        });
    } else if (flag === "View") {
      console.log("data-- -- ", this.state.data[dataIndex].phone);
      this.setState({
        viewDetailsModal: true,
        loaderShow: true,
        houseHoldTab: 0,
      });
      this.getUSerDetails(
        this.state.data[dataIndex].id,
        this.state.data[dataIndex].enrollFlag
      );
      let obj = [];
      if (this.state.data[dataIndex].enrollFlag == true) {
        obj = [
          {
            key: "Name",
            value:
              this.state.data[dataIndex].firstName +
              " " +
              this.state.data[dataIndex].lastName,
            type: "STR",
          },
          {
            key: "Email",
            value: this.state.data[dataIndex].email,

            type: "STR",
          },
          {
            key: "Phone",
            value: this.state.data[dataIndex].phone
              ? this.state.countryCode +
                " " +
                this.formatPhoneNumber(this.state.data[dataIndex].phone)
              : null,
            type: "STR",
          },
          {
            key: "Address",
            value: this.state.data[dataIndex].addressLine1,
            type: "STR",
          },
          {
            key: "City",
            value: this.state.data[dataIndex].city,
            type: "STR",
          },
          
          {
            key: "State",
            value: this.state.data[dataIndex].state,
            type: "STR",
          },
          {
            key: "Zip Code",
            value: this.state.data[dataIndex].zipCode,
            type: "STR",
          },
          {
            key: "Plan Purchased",
            value: this.state.data[dataIndex].planName,
            type: "STR",
          },
          {
            key: "Active Date",
            value: moment
              .utc(this.state.data[dataIndex].benefitBegin)
              .format("MMMM DD, YYYY"),
            type: "STR",
          },
          // {
          //     key: 'Paid through date',
          //     value: this.state.profileProspectData[dataIndex].benefits[0].premiumPaidDate ? moment(this.state.profileProspectData[dataIndex].benefits[0].premiumPaidDate).format('MMMM DD, YYYY') : 'N/A',
          //     type: 'STR'
          // },
          {
            key: "Status",
            value: this.state.data[dataIndex].status,
            type: "STR",
          } /*,{
                        key : 'Cost Of Program',
                        value : response.data.response.cost_of_program,
                        type : 'STR'
                    }*/,
          {
            key: "Names of dependents and relationship",
            value: this.state.data[dataIndex].dependents,
            type: "ARR",
          },
        ];
        this.setState({
          viewHouseholdData: obj,
          loaderShow: false,
          // detailsData: { memberIdSource: response.data.response.memberIdSource, id: id, disableFlag: false, enrollFlag: flag }
        });
      } else {
        obj = [
          {
            key: "Name",
            value:
              this.state.data[dataIndex].firstName +
              " " +
              this.state.data[dataIndex].lastName,
            type: "STR",
          },
          {
            key: "Email",
            value: this.state.data[dataIndex].email,
            type: "STR",
          },
          {
            key: "Phone",
            value: this.state.data[dataIndex].phone
              ? this.state.countryCode +
                " " +
                this.formatPhoneNumber(this.state.data[dataIndex].phone)
              : null,
            type: "STR",
          },
        ];
        this.setState({
          viewHouseholdData: obj,
          loaderShow: false,
          // detailsData: { memberIdSource: response.data.response.memberIdSource, id: id, disableFlag: true, enrollFlag: flag }
        });
      }
    } else {
      this.setState({
        loaderShow: false,
        flag: flag,
        selectedMemberId: id,
        prospectFullName: prospectName,
        reAssignAgentModal: true,
        errMsg: "",
      });
    }
  };
  render() {
    console.log("requestOptions:::", this.state.viewUserData);
    const columns =
      this.state.selctAgentOrProspect == "0"
        ? [
            {
              label: "Channel Name",
              name: "clientName",
              options: { filter: false, sort: false },
            },
            {
              label: "Agent Name",
              name: "agentName",
              options: { filter: false, sort: false },
            },
            {
              label: "Agent ID",
              name: "brokerId",
              options: { filter: false, sort: false },
            },
            {
              label: "Email ID",
              name: "email",
              options: { filter: false, sort: false },
            },
            {
              label: "Total Collection",
              name: "totalCollection",
              options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return <div>${value ? Number(value).toFixed(2) : 0}</div>;
                },
              },
            },
            // {label:'Action',name:'',options:{filter:false,sort: false,
            //     customBodyRender: (value, tableMeta, updateValue) => {
            //         return (
            //             <div>
            //                 <IconButton onClick={() =>{}}>
            //                     <MoreVertIcon/>
            //                 </IconButton>
            //             </div>
            //         );
            //     }
            // }}
          ]
        : [
            {
              label: "Date Added",
              name: "createdDate",
              options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return <div>{moment(value).format("MMM DD, YYYY")}</div>;
                },
              },
            },
            {
              label: "Prospect Name",
              name: "memberName",
              options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return <div>{value ? value : "N/A"}</div>;
                },
              },
            },
            {
              label: "Email ID",
              name: "email",
              options: { filter: false, sort: false },
            },
            {
              label: "Agent Name",
              name: "agentName",
              options: { filter: false, sort: false },
            },
            {
              label: "Channel Name",
              name: "clientName",
              options: { filter: false, sort: false },
            },
            {
              label: "Status",
              name: "completionStatus",
              options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return this.state.data.filter(
                    (res) => res.email == tableMeta.rowData[2]
                  )[0].enrollFlag ? (
                    <div className="flagSuccess">App Success</div>
                  ) : (
                    <div className="flag">
                      {value == 0
                        ? "Instructions"
                        : value == 1
                        ? "Set up Family"
                        : value == 2
                        ? "Select Plan"
                        : value == 3
                        ? "Select Add-Ons"
                        : value == 4
                        ? "View Quote"
                        : value == 5
                        ? "Personal Info"
                        : value == 6
                        ? "Set up Payment"
                        : value == 7
                        ? "Select Dates"
                        : value == 8
                        ? "Submit Application"
                        : value == 9
                        ? "Submit Application"
                        : null}
                    </div>
                  );
                },
              },
            },
            {
              label: "Action",
              name: "id",
              options: {
                filter: false,
                sort: false,
                customBodyRender: (
                  value,
                  tableMeta,
                  updateValue,
                  dataIndex
                ) => {
                  console.log(
                    "- - - - -- --  ",
                    value,
                    " ",
                    tableMeta,
                    "",
                    dataIndex
                  );
                  return (
                    <div>
                      <PopupState variant="popover" popupId="demo-popup-menu">
                        {(popupState) => (
                          <React.Fragment>
                            <IconButton {...bindTrigger(popupState)}>
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              {...bindMenu(popupState)}
                              onClick={popupState.close}
                              getContentAnchorEl={null}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                              }}
                              transformOrigin={{
                                vertical: -10,
                                horizontal: 30,
                              }}
                            >
                              <MenuItem
                                key={0}
                                value="view"
                                disabled={false}
                                onClick={() =>
                                  this.selectMenu(
                                    value,
                                    tableMeta.rowData[1],
                                    "View",
                                    tableMeta.rowIndex
                                  )
                                }
                              >
                                View Details
                              </MenuItem>
                              {/* <MenuItem key={0} value='view' disabled={this.state.data.filter(res=>res.email == tableMeta.rowData[2])[0].empid != null } onClick={() => this.selectMenu(value, tableMeta.rowData[1], "Re-assign", tableMeta.rowIndex )}>Re-assign Agent</MenuItem> */}
                              {/* <MenuItem key={1} value='view' disabled={this.state.data.filter(res=>res.email == tableMeta.rowData[2])[0].enrollFlag} onClick={() => this.selectMenu(value, tableMeta.rowData[1], "PushEnrollment", tableMeta.rowIndex)}>Push Enrollment</MenuItem> */}
                              <MenuItem
                                key={1}
                                value="view"
                                disabled={
                                  this.state.data.filter(
                                    (res) => res.email == tableMeta.rowData[2]
                                  )[0].enrollFlag
                                }
                                onClick={() =>
                                  this.selectMenu(
                                    value,
                                    tableMeta.rowData[1],
                                    "DELETE",
                                    tableMeta.rowIndex
                                  )
                                }
                              >
                                Delete
                              </MenuItem>

                              {LunchAccess.filter(
                                (x) =>
                                  x ==
                                  localStorage.getItem("email").toLowerCase()
                              )[0] && (
                                <MenuItem
                                  key={2}
                                  value="view"
                                  disabled={
                                    !this.state.data.filter(
                                      (res) => res.email == tableMeta.rowData[2]
                                    )[0].enrollFlag
                                  }
                                  onClick={() =>
                                    this.lunchMemberPortal(
                                      this.state.data.filter(
                                        (res) =>
                                          res.email == tableMeta.rowData[2]
                                      )[0].email
                                    )
                                  }
                                >
                                  Launch Member Portal
                                </MenuItem>
                              )}
                            </Menu>
                          </React.Fragment>
                        )}
                      </PopupState>
                    </div>
                  );
                },
              },
            },
          ];
    const options = {
      filter: false,
      filterType: "dropdown",
      responsive: "standard",
      serverSide: true,
      selectableRowsHeader: false,
      count: this.state.count,
      selectableRows: false,
      rowsPerPage: this.state.rowsPerPage,
      search: false,
      confirmFilters: true,
      viewColumns: false,
      print: false,

      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
          <div style={{ marginTop: "40px" }}>
            <button
              variant="contained"
              onClick={() => this.handleFilterSubmit(applyNewFilters)}
            >
              Apply Filters
            </button>
          </div>
        );
      },
      onFilterConfirm: (filterList) => {
        console.log("onFilterConfirm");
        console.log(filterList);
      },

      onFilterDialogOpen: () => {
        console.log("filter dialog opened");
      },
      onFilterDialogClose: () => {
        console.log("filter dialog closed");
      },
      onFilterChange: (column, filterList, type) => {
        // if (type === 'chip') {
        var newFilters = () => filterList;
        console.log("updating filters via chip", filterList);
        this.handleFilterSubmit(newFilters);
        // }
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case "changePage":
            this.changePage(tableState.page, tableState.rowsPerPage);
            break;
          case "changeRowsPerPage":
            this.changePage(tableState.page, tableState.rowsPerPage);
            break;
          default:
            break;
        }
      },
      customToolbar: () => {
        return <></>;
      },
    };

    const transactionOption = {
      selectableRows: false,
      filter: false,
      selectableRowsHeader: false,
      count:
        this.state.activeTab === 1
          ? this.state.transCount
          : this.state.activeTab === 2 && this.state.loginCount,
      rowsPerPage: 10,
      search: false,
      serverSide: false,
      download: false,
      viewColumns: false,
      print: false,
      fixedHeader: true,
      textLabels: {
        body: {
          noMatch: "Sorry, no records found",
        },
      },
    };
    // const reAssignHistoryOption = {
    //     selectableRows: false,
    //     filter: false,
    //     selectableRowsHeader: false,
    //     count: this.state.reAssignHistoryData.length,
    //     rowsPerPage: 10,
    //     search: false,
    //     serverSide: false,
    //     download: false,
    //     viewColumns: false,
    //     print: false,
    //     fixedHeader: true,
    //     textLabels: {
    //         body: {
    //             noMatch: "Sorry, no records found",
    //         },
    //     }
    // };

    let currentScreen = "";
    if (this.state.activeTab === 0) {
      currentScreen = (
        <div
          style={{
            margin: "15px",
            borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            fontFamily: "Roboto, Arial, Helvetica, sans-serif",
          }}
        >
          <Grid item sm={12} xs={12} md={12} lg={12}>
            {this.state.viewUserData && this.state.viewUserData.length > 3 ? (
              this.state.viewUserData.map((row, index, key) =>
                row.type === "STR" ? (
                  <div
                    style={{
                      display: "flex",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      padding: "10px",
                    }}
                  >
                    <Grid item xs={3} key={index} sm={3} md={3} lg={2}>
                      {row.key}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={6}
                      md={6}
                      lg={6}
                      key={index + "gd"}
                      style={{ fontWeight: "bold" }}
                    >
                      {row.value}
                    </Grid>
                    {row.memberIdSource && (
                      <Grid
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        lg={2}
                        style={{ fontWeight: "bold" }}
                      >
                        <Button
                          style={{
                            outline: "none",
                            color: "#fff",
                            backgroundColor: "#33AFB0",
                          }}
                          onClick={() =>
                            this.viewIDcard(row.memberIdSource, row.emailID)
                          }
                        >
                          View ID Card
                        </Button>
                      </Grid>
                    )}
                  </div>
                ) : (
                  row.type === "ARR" && (
                    <div
                      style={{
                        display: "flex",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        padding: "10px",
                      }}
                    >
                      <Grid item xs={3} key={index + "sm"} sm={3} md={3} lg={2}>
                        {row.key}
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        sm={6}
                        md={6}
                        lg={6}
                        key={index + "li"}
                        style={{ fontWeight: "bold" }}
                      >
                        {row.value.length > 0 ? (
                          <ul style={{ listStyleType: "none" }}>
                            {row.value.map((childRow) => (
                              <li style={{ width: "100%" }}>
                                <span
                                  style={{
                                    float: "left",
                                    width: "50%",
                                    marginLeft: "-40px",
                                  }}
                                >
                                   {this.prefixSuffixMiddleName(
                                      childRow.prefix,
                                      childRow.firstName,
                                      childRow.middleName,
                                      childRow.lastName,
                                      childRow.suffix
                                  )}
                                </span>
                                <span style={{ float: "right", width: "50%" }}>
                                  {childRow.relation}
                                </span>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <span>NA</span>
                        )}
                      </Grid>
                    </div>
                  )
                )
              )
            ) : (
              <div>
                {this.state.viewUserData && this.state.viewUserData.length > 3
                  ? this.state.viewUserData.map((row, index, key) => (
                      <div
                        style={{
                          display: "flex",
                          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          padding: "10px",
                        }}
                      >
                        <Grid item xs={3} key={index} sm={3} md={3} lg={2}>
                          {row.key}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sm={6}
                          md={6}
                          lg={6}
                          key={index + "gd"}
                          style={{ fontWeight: "bold" }}
                        >
                          {row.value}
                        </Grid>
                      </div>
                    ))
                  : null}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    textAlign: "center",
                    display:
                      this.state.detailsData.enrollFlag &&
                      !this.state.loaderShow
                        ? "block"
                        : "none",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Member onboarding in progress
                    <br />
                    please call agent support
                    {/* If it's more than two business days since enrollment, please call agent support: (866) 638-9355 option 3. */}
                  </span>
                </Grid>
              </div>
            )}
          </Grid>
        </div>
      );
    } else if (this.state.activeTab === 1) {
      currentScreen = (
        <Grid
          item
          sm={6}
          xs={6}
          md={12}
          lg={12}
          style={{ fontFamily: "Roboto, Arial, Helvetica, sans-serif" }}
        >
          {/* <MuiThemeProvider theme={this.getMuiThemeTran()}>
                    <MUIDataTable
                        // title={"Active Prospects"}
                        data={this.state.transactionData}
                        columns={this.transactionColumn}
                        options={transactionOption}
                    />
                </MuiThemeProvider> */}
          <TransactionTable tableData={this.state.transactionData} />
        </Grid>
      );
    } else if (this.state.activeTab === 2) {
      currentScreen = (
        <Grid
          item
          sm={6}
          xs={6}
          md={12}
          lg={12}
          style={{ fontFamily: "Roboto, Arial, Helvetica, sans-serif" }}
        >
          <MuiThemeProvider theme={this.getMuiThemeTran()}>
            <MUIDataTable
              // title={"Active Prospects"}
              data={this.state.loginData}
              columns={this.loginColumn}
              options={transactionOption}
            />
          </MuiThemeProvider>
        </Grid>
      );
    } else if (this.state.activeTab === 3) {
      currentScreen = (
        <Grid
          item
          sm={6}
          xs={6}
          md={12}
          lg={12}
          style={{ fontFamily: "Roboto, Arial, Helvetica, sans-serif" }}
        >
          {/* <MuiThemeProvider theme={this.getMuiThemeTran()}>
                    <MUIDataTable
                        data={this.state.reAssignHistoryData}
                        columns={this.reAssignHistoryColumn}
                        options={reAssignHistoryOption}
                    />
                </MuiThemeProvider> */}
        </Grid>
      );
    }
    return (
      <div style={{ width: "99%", margin: "2% 1% 1% 1%", minHeight: "85vh" }}>
        {this.state.loaderShow ? <Loader></Loader> : ""}
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            style={{ backgroundColor: "red" }}
            title={
              <div
                style={{
                  marginTop: "4%",
                  width: "120%",
                  display: "flex",
                  marginLeft: "100px",
                  marginBottom: "10px",
                }}
              >
                <FormControl
                  style={{
                    width: "160px",
                    marginTop: "-14px",
                    marginLeft: "-100px",
                  }}
                >
                  <InputLabel style={{ textAlign: "left" }}>Select</InputLabel>
                  <Select
                    label="Select"
                    name="name"
                    style={{ textAlign: "left" }}
                    value={this.state.selctAgentOrProspect}
                    onChange={(event) => this.handleselctAgentOrProspect(event)}
                  >
                    <MenuItem value="0">Agent</MenuItem>
                    <MenuItem value="1">Prospect</MenuItem>
                  </Select>
                </FormControl>

                {this.state.selctAgentOrProspect == "0" ? (
                  <FormControl
                    style={{
                      width: "160px",
                      marginTop: "-14px",
                      marginLeft: "17px",
                    }}
                  >
                    <InputLabel style={{ textAlign: "left" }}>
                      Search By
                    </InputLabel>
                    <Select
                      label="Search By"
                      name="name"
                      style={{ textAlign: "left" }}
                      // style={customStyleUHS.searchByStyle}
                      value={this.state.searchTypeValue}
                      onChange={(event) => this.handleChageSearchType(event)}
                    >
                      <MenuItem value="0">Email ID</MenuItem>
                      <MenuItem value="1">Agent ID</MenuItem>
                      <MenuItem value="3">Agent Name</MenuItem>
                      <MenuItem value="2">Channel</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl
                    style={{
                      width: "160px",
                      marginTop: "-14px",
                      marginLeft: "17px",
                    }}
                  >
                    <InputLabel style={{ textAlign: "left" }}>
                      Search By
                    </InputLabel>
                    <Select
                      label="Search By"
                      name="name"
                      style={{ textAlign: "left" }}
                      // style={customStyleUHS.searchByStyle}
                      value={this.state.searchTypeValue}
                      onChange={(event) => this.handleChangeType(event)}
                    >
                      <MenuItem value="0">Email ID</MenuItem>
                      <MenuItem value="1">Name</MenuItem>
                      <MenuItem value="2">Status</MenuItem>
                      <MenuItem value="3">Channel</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {this.state.searchTypeValue === "hjhj" ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      //    required
                      // error=''
                      autoComplete="off"
                      margin="none"
                      label="Select Date"
                      format="MMMM dd,yyyy"
                      value={this.state.targetDate}
                      onFocus={(e) => e.target.blur()}
                      style={{ marginLeft: "21px", marginTop: "-20px" }}
                      // helperText={this.searchRecord}
                      inputProps={{
                        style: {
                          fontSize: "12.8px",
                          fontfamily: "Roboto",
                          marginTop: "10px",
                          //    paddingLeft:'7px'
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          paddingRight: 10,
                          paddingTop: 12,
                          color: "grey",
                        },
                      }}
                      onChange={(event) => this.handleDate(event)}
                      variant="filled"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      views={["year", "month", "date"]}
                      openTo="year"
                      minDate={new Date("01/01/2020")}
                    />
                  </MuiPickersUtilsProvider>
                ) : this.state.searchTypeValue === "2" ||
                  (this.state.searchTypeValue === "3" &&
                    this.state.selctAgentOrProspect == "1") ? (
                  this.state.selctAgentOrProspect == "0" ||
                  (this.state.searchTypeValue === "3" &&
                    this.state.selctAgentOrProspect == "1") ? (
                    <FormControl
                      style={{
                        width: "160px",
                        marginTop: "-14px",
                        marginLeft: "33px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label" style={{}}>
                        Select Channel
                      </InputLabel>
                      <Select
                        name="name"
                        style={{ textAlign: "left" }}
                        value={this.state.searchText}
                        onChange={(event) => this.setSearch(event)}
                      >
                        {this.state.channelids &&
                          this.state.channelids.map((channel) => (
                            <MenuItem value={channel.clientId}>
                              {channel.clientName} ({channel.clientId})
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl
                      style={{
                        width: "160px",
                        marginTop: "-14px",
                        marginLeft: "33px",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label" style={{}}>
                        Select Status
                      </InputLabel>
                      <Select
                        name="name"
                        style={{ textAlign: "left" }}
                        value={this.state.searchText}
                        onChange={(event) => this.setSearch(event)}
                      >
                        <MenuItem value="0">Instructions</MenuItem>
                        <MenuItem value="1">Set up Family</MenuItem>
                        <MenuItem value="2">Select Plan</MenuItem>
                        <MenuItem value="3">Select Add-Ons</MenuItem>
                        <MenuItem value="4">View Quote</MenuItem>
                        <MenuItem value="5">Personal Info</MenuItem>
                        <MenuItem value="6">Set up Payment</MenuItem>
                        <MenuItem value="7">Select Dates</MenuItem>
                        <MenuItem value="8">Submit Application</MenuItem>
                        <MenuItem value="9">App Success</MenuItem>
                      </Select>
                    </FormControl>
                  )
                ) : (
                  <TextField
                    style={{ marginLeft: "28px", marginTop: "3px" }}
                    value={this.state.searchText}
                    disabled={this.state.searchShow}
                    onChange={(event) => this.setSearch(event)}
                  ></TextField>
                )}

                <CrudButton
                  color="secodary"
                  style={{
                    boxShadow: "none",
                    height: "40px",
                    width: "40px",
                    marginLeft: "13px",
                  }}
                  aria-label="search"
                  disabled={
                    this.state.searchText == "" || this.state.targetDate == ""
                  }
                  onClick={() => this.searchRecord()}
                >
                  <SearchIcon style={{ height: 25, width: 25 }} />
                </CrudButton>
                <Tooltip title="Refresh" aria-label="refresh">
                  <CrudButton
                    style={{
                      boxShadow: "none",
                      width: "40px",
                      height: "40px",
                      marginLeft: "10px",
                    }}
                    onClick={this.refreshTable}
                  >
                    <RefreshIcon style={{ height: 25, width: 25 }} />
                  </CrudButton>
                </Tooltip>
                {/* <Tooltip title="Back To Home" aria-label="home">
                <CrudButton style={{boxShadow:'none',width:'40px',height:'40px',marginLeft:'10px'}} onClick={()=>this.handleBack()}><HomeIcon /></CrudButton>
                </Tooltip> */}
              </div>
            }
            data={this.state.data}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>

        <Modal
          size="md"
          show={this.state.reAssignAgentModal}
          onHide={(event) =>
            this.setState({
              reAssignAgentModal: false,
              loaderShow: false,
              selectedAgent: "",
              selectedMemberId: null,
              code: null,
              flag: "",
            })
          }
          backdrop="static"
          centered
        >
          <Modal.Header style={customcss.modal_header} closeButton>
            <Modal.Title>
              {this.state.flag === "Re-assign" ? "Re-assign Agent" : "Warning"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            {this.state.loaderShow ? <Loader></Loader> : ""}
            {this.state.flag === "Re-assign" ? (
              <div>
                <div style={{ marginBottom: "15px" }}>
                  Please assign a new agent to assist{" "}
                  <b>{this.state.prospectFullName}</b>
                </div>
                <div>
                  {this.state.agentList && (
                    <CommonDropDwn
                      setChild={this.setAgent.bind(this)}
                      reqFlag={true}
                      name={"Agent"}
                      label={"Please select agent"}
                      value={this.state.selectedAgent}
                      fieldType={"dropDwn"}
                      disable={false}
                      style={customStyle.dropDown}
                      List={this.state.agentList}
                      errMsg={"Select Agent"}
                      helperMsg={"Agent required"}
                      parentDetails={{ name: "selectedAgent" }}
                    ></CommonDropDwn>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div style={{ marginBottom: "15px", textAlign: "center" }}>
                  You are attempting to delete{" "}
                  <b>{this.state.prospectFullName}</b> data from the system.
                  Proceed?
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              color="primary"
              style={{ fontSize: "12px", minWidth: "100px" }}
              disabled={
                this.state.flag === "Re-assign" &&
                this.state.selectedAgent == ""
              }
              onClick={() =>
                this.submitReAssignAgent(this.state.flag === "Re-assign")
              }
            >
              {this.state.flag === "Re-assign" ? "UPDATE" : "YES"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                fontSize: "12px",
                minWidth: "100px",
                marginLeft: "15px",
              }}
              onClick={() =>
                this.setState({
                  reAssignAgentModal: false,
                  selectedAgent: "",
                  flag: "",
                  loaderShow: false,
                  selectedMemberId: null,
                })
              }
            >
              CANCEL
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.viewDetailsModal}
          onHide={(event) =>
            this.setState({
              viewDetailsModal: false,
              loaderShow: false,
              viewUserData: [],
              transactionData: [],
              loginData: [],
              transCount: 0,
              loginCount: 0,
              activeTab: 0,
            })
          }
          centered
          backdrop="static"
        >
          <Modal.Header style={{ padding: 0 }}>
            {this.state.loaderShow ? <Loader></Loader> : ""}
            <div
              style={{ flexGrow: 1, overflowX: "hidden", overflowY: "auto" }}
            >
              <Grid container>
                <Grid item xs={11} sm={11} md={11} lg={11}>
                  <AntTabs
                    value={this.state.activeTab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <AntTab
                      label="VIEW DETAILS"
                      className="ant-col-15"
                      style={{
                        paddingLeft: "0px",
                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                      }}
                    />
                    <AntTab
                      label="VIEW TRANSACTION DETAILS"
                      disabled={this.state.detailsData.disableFlag}
                      style={{
                        paddingLeft: "0px",
                        fontFamily: "Roboto, Arial, Helvetica, sans-serif",
                      }}
                    />
                    {/* <AntTab label="VIEW ACCESS LOGS" disabled={this.state.detailsData.disableFlag} style={{ paddingLeft: '0px', fontFamily: 'Roboto, Arial, Helvetica, sans-serif' }} /> */}
                  </AntTabs>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  style={{ backgroundColor: "#33afb0" }}
                >
                  <IconButton
                    style={{
                      float: "right",
                      marginRight: "10px",
                      marginTop: "10px",
                      width: "25px",
                      color: "white",
                      height: "25px",
                    }}
                    onClick={(event) =>
                      this.setState({
                        viewDetailsModal: false,
                        loaderShow: false,
                        viewUserData: [],
                        transactionData: [],
                        loginData: [],
                        transCount: 0,
                        loginCount: 0,
                        detailsData: {},
                        activeTab: 0,
                      })
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Modal.Header>
          <Modal.Body style={{ padding: 0, maxHeight: "450px" }}>
            <div
              style={{
                flexGrow: 1,
                height: "450px",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {currentScreen}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          fullWidth
          size="md"
          show={this.state.idCardURL !== null}
          onHide={() => this.setState({ idCardURL: null })}
          centered
          backdrop="static"
        >
          <Modal.Body style={{ padding: 0, maxHeight: "100%", width: "790px" }}>
            <div
              style={{
                display: this.state.idCardURL ? "flex" : "none",
                justifyContent: "flex-end",
                background: "#f1f1f1",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                width: "790px",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={(e) => {
                  this.setState({ idCardURL: null });
                }}
                style={{
                  color: "black",
                  width: "30px",
                  height: "30px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>

            {this.state.loaderShow ? <Loader></Loader> : ""}
            <iframe
              src={this.state.idCardURL}
              title="Common Module"
              style={{
                width: "790px",
                height: "453px",
                border: "none",
                margin: "auto",
              }}
            ></iframe>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default AgentMemberSearch;
