import React, { useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Provider } from 'react-redux';
import Home from './Components/Screens/Home'
import './Assets/CSS/window.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Link, Route, Switch, Router, Redirect } from 'react-router-dom';
import Amplify, { Auth, Analytics, Storage } from 'aws-amplify';
import { withAuthenticator, Authenticator } from 'aws-amplify-react';
import store from './store/store';
import SignIn from './Components/authentication/SignIn';
import SignUp from './Components/authentication/Signup';
import RootComponent from './Components/Screens/rootComponent';
import Footer from './Components/Commonscreens/Footer'
import UploadCSV from './Components/Screens/UploadCSV';
import awsConfig from './awsConfig';
import axios from 'axios'
import Transaction from './Components/Screens/changePayment/Transaction';
import CreatePayment from "./Components/Screens/payment/CreatePayment";
import Layout from './Components/Layout'
import IdleSessionHandle from './Components/authentication/IdleSessionHandle';
import UrlEncoding from './Components/Screens/urlEncoding';
import AgentCreate from './Components/Screens/AgentCreation';
import AuditLog from './Components/Screens/Auditlog';
import './App.css'
import AgentMemberSearch from './Components/Screens/AgentMemberSearch';
import MemberDashboard from './Components/Screens/members-dashboard/MemberDashboard';
import Notification from './Components/Screens/notifications/Notification';
import PaidThruReportLanding from './Components/Screens/paidThruReport/PaidThruReportLanding';
import CognitoDashboard from './Components/Screens/cognito-dashboard/CognitoDashboard';
import CognitoSetting from './Components/Screens/cognito-setting/CognitoSetting';
import OperationsDashboard from './Components/Screens/OperationDashboard/OperationDashboard';
// import ChannelCreate from './Components/Screens/ChannelCreation';
import Reports from './Components/Screens/Reports';
import TransactionReport from './Components/Screens/TransactionReport';
import DeliquencyReport from './Components/Screens/DeliquencyReport';
import AgeAudit from './Components/Screens/AgeAudit';
import RecurringTransaction from './Components/Screens/RecurringTransaction'



Amplify.configure(awsConfig);


const analyticsConfig = {
    AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: '564ed58f19c44790bb1888dc2332a35a',
        // Amazon service region
        region: 'us-east-1',
        mandatorySignIn: false,
    }
}

if (process.env.REACT_APP_BUILD == 'dev') {
    // Analytics.configure(analyticsConfig)
}
const storageConfig = {
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_notification_s3_bucket, //REQUIRED -  Amazon S3 bucket name
            region: 'us-east-2', //OPTIONAL -  Amazon service region
        }
    }
}

Amplify.configure(storageConfig);

global.load = 0;

const AuthContext = React.createContext({
    authenticated: false
});

export const Modules = {
    Home: '/admin',
    encodeURL: '/encode-url',
    OnBoardAgent: '/onboard-agent',
    AuditLogs: '/audit-log',
    Payment: '/create-payment',
    PaidThruReport: '/paid-thru-report',
    MemberDashboard: '/member-dashboard',
    CognitoDashboard: '/cognito-dashboard',
    CognitoSetting: '/cognito-setting',
    Notification: '/notification',
    OperationsDashboard: '/operationsdashboard',
    userSearch: '/userSearch',
    // createChannel: '/createChannel',
    Reports: '/Reports',
    TransactionReport: '/TransactionReport',
    DeliquencyReport:'/DeliquencyReport',
   AgeAudit:'/AgeAudit',
   RecurringTransaction:'/RecurringTransaction'
   
}


class App extends React.Component {

    constructor(props) {
        super(props);
        let isLoggedIn = sessionStorage.isLogged == 'true' ? true : false
        this.state = {
            loaderShow: false,
            userLoggedIn: isLoggedIn,
            // agentId : "",
            // clientId : "",
            // associationId : "",
            // clientName : "",
            agentName: ''
        }
    }


    componentDidMount() {
        this.getToken();
        Auth.currentAuthenticatedUser()
            .then((user) => {
                console.log('from root user', user)
                let data = this.parseJwt(user.signInUserSession.idToken.jwtToken);

                this.setState({ agentName: data.name, userLoggedIn: true, })
                sessionStorage.setItem('isLogged', true);
                localStorage.setItem('email', user.attributes.email);
               

            }).catch((err) => {
                sessionStorage.setItem('isLogged', false);
                console.log('not logged in')
                // window.location.replace("/login");
                this.setState({
                    // loaderShow: false,
                    userLoggedIn: false
                })
            });
    }

    getToken=() => {
        let request = {
            "username": "Innovation",
            "password": "Innovation"
        }
        if (process.env.REACT_APP_BUILD == 'prod') {
            request.password = "IxLTA0LTI2VDExOjI4OjI4Wg=="
        }
        let innoOrganization = Buffer.from(`INNOVATION;${new Date()}`).toString('base64')
        axios.post(process.env.REACT_APP_BaseAPIURL + "api/v1/login", request, {headers:{
            'X-Tenant-Id': innoOrganization,
        }}).then(response => {
            if (response.data.message) { 
                console.log("failed to get token")
                alert(response.data.message)
        }
        else {
            let bearer = response['headers'].authorization
            var array = bearer.split("Bearer ")
            localStorage.setItem("innobearerToken", array[1]);
        }})
    }
    setUserLogin = (value) => {
        this.setState({
            // loaderShow: false,
            userLoggedIn: value
        })
    }
    parseJwt = (id_token) => {

        let base64Url = id_token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join('')
        );

        let token = JSON.parse(jsonPayload);
        return token;
    };

    handleBack = () => {
        this.props.history.push(Modules.Home)
    }



    render() {

        console.log('::this.state.userLoggedIn::', this.state.userLoggedIn)
        return (
            <Provider store={store}>
                <AuthContext.Provider value={{
                    authenticated: this.state.userLoggedIn
                }}>
                    <div >

                        <BrowserRouter>
                            <Switch>

                                <Route exact path="/" >
                                    <Redirect
                                        to={{
                                            pathname: this.state.userLoggedIn ? Modules.Home : "/login",
                                        }}
                                    />

                                </Route>

                                <LoadRouteWithCheck path="/login">
                                    <SignIn setUserLogin={this.setUserLogin} />
                                </LoadRouteWithCheck>

                                <LoadRouteWithCheck path="/signup">
                                    <SignUp />
                                </LoadRouteWithCheck>
                                {/* <Route path="/signup" component={SignUp} />
                            <Route path="/login" render={(props) => {
                                return <SignIn {...props} setUserLogin={this.setUserLogin} />
                            }} /> */}
                                {/* <Route path="/home" component={Home} /> */}
                                {/* <Route path="/uploadCSV" component={UploadCSV} /> */}
                                {/* <Route path="/change-payment" component={Transaction} /> */}

                                <AppPrivateRoutes setUserLogin={this.setUserLogin} />


                                <Route path="*" render={() => {
                                    return (
                                        <div className="page-notfound">
                                            <p>Page not found!</p>
                                        </div>
                                    )
                                }} />
                            </Switch>
                        </BrowserRouter>

                        {
                            this.state.userLoggedIn &&
                            <IdleSessionHandle />
                        }

                    </div>
                </AuthContext.Provider>
            </Provider>

        );
    }
}


export default App;


const PrivateRoute = ({ children, ...rest }) => {
    const auth = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.authenticated ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}


const LoadRouteWithCheck = ({ children, ...rest }) => {
    const auth = useContext(AuthContext);
    return (
        <Route
            {...rest}
            render={({ location }) => // children
                auth.authenticated ? (
                    <Redirect
                        to={{
                            pathname: Modules.Home,
                            state: { from: location }
                        }}
                    />
                ) : (
                        children
                    )
            }
        />
    );
}

const AppPrivateRoutes = (props) => {
    let history = useHistory();

    const handleBack = () => {
        history.goBack()
    }
    return (
        <Layout setUserLogin={props.setUserLogin}>
            <PrivateRoute path={Modules.Home}>
                <Home />
            </PrivateRoute>

            <PrivateRoute path={Modules.encodeURL}>
                <UrlEncoding onClick={handleBack} />
            </PrivateRoute>

            <PrivateRoute path={Modules.OnBoardAgent}>
                <AgentCreate onClick={handleBack} />
            </PrivateRoute>

            <PrivateRoute path={Modules.AuditLogs}>
                <AuditLog onClick={handleBack} />
            </PrivateRoute>

            <PrivateRoute path={Modules.Payment}>
                <CreatePayment onClick={handleBack} />
            </PrivateRoute>

            <PrivateRoute path={Modules.PaidThruReport}>
                <PaidThruReportLanding goBack={handleBack} />
            </PrivateRoute>

            <PrivateRoute path={Modules.MemberDashboard}>
                <MemberDashboard goBack={handleBack} />
            </PrivateRoute>

            <PrivateRoute path={Modules.Notification}>
                <Notification goBack={handleBack} />
            </PrivateRoute>

            <PrivateRoute path={Modules.CognitoDashboard}>
                <CognitoDashboard goBack={handleBack} />
            </PrivateRoute>
            <PrivateRoute path={Modules.CognitoSetting}>
                <CognitoSetting goBack={handleBack} />
            </PrivateRoute>
            <PrivateRoute path={Modules.OperationsDashboard}>
                <OperationsDashboard goBack={handleBack} />
            </PrivateRoute>
            <PrivateRoute path={Modules.userSearch}>
                <AgentMemberSearch goBack={handleBack} />
            </PrivateRoute>
            {/* <PrivateRoute path={Modules.createChannel}>
                <ChannelCreate onClick={handleBack} />
            </PrivateRoute> */}
              
              
                    <PrivateRoute path={Modules.Reports}>
        <Reports goBack={handleBack} />
    </PrivateRoute>
                

            
            <PrivateRoute path={Modules.TransactionReport}>
                <TransactionReport goBack={handleBack} />
            </PrivateRoute>
            <PrivateRoute path={Modules.DeliquencyReport}>
                <DeliquencyReport goBack={handleBack} />
            </PrivateRoute>
            <PrivateRoute path={Modules.AgeAudit}>
                <AgeAudit goBack={handleBack} />
            </PrivateRoute>
            <PrivateRoute path={Modules.RecurringTransaction}>
                <RecurringTransaction goBack={handleBack} />
            </PrivateRoute>
           
            {/* <PrivateRoute path={Modules.EmailTemplateList}>
                <EmailTemplate goBack={handleBack} />
            </PrivateRoute>
            
            <PrivateRoute path={Modules.TemplateView}>
                <TemplateView goBack={handleBack} />
        </PrivateRoute> */}


        </Layout>
    )
}
