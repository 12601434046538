import React, { Component } from 'react';
import Sample from '../Commonscreens/CommonTextField.js';
import customStyle from '../../Assets/CSS/stylesheet';
import { withStyles,createStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {commomPost} from '../Services/CommonServices';
import Grid from '@material-ui/core/Grid';
import Footer from '../Commonscreens/Footer';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Loader from '../Commonscreens/loader';
import axios from 'axios';

const DoneBtn = withStyles(
    customStyle.copyBtn
)(Button);

const GenBtn = withStyles(
    customStyle.genrateBtn
)(Button);

const GenBtn1 = withStyles(
    customStyle.genrateBtn1
)(Button);


class RecurringTransaction extends Component {
    constructor(props){
        super(props);
        this.state={
            bid:'',
            cid:'',
            aid:'',
            url:'',
            genrate:true,
            loading:false,
            baseUrl:'',
            commonModuleURL:''
        }
        
      
       
    }


    componentDidMount (){
        this.setState({
            loaderShow : true
        });
        this.loadData();
    }


    



loadData = async ()=>{
    let memberIdSource = localStorage.getItem('sourceid')
    let type = '';
    let request = `memberid=${memberIdSource}&type=${type}&email=${localStorage.getItem('email')}`
    let query = await this.encryptData(request)
    let _reportURL  = this.getCommonModuleURL('DataTable', query)
    

    this.setState({
      showHealthCard: true,
      visible: true,
      commonModuleURL: _reportURL,
      loaderShow: false
    })
}
    

 encryptMemberId = async encodeString => {
    let request = {
      memberId: encodeString
    }
    let response = ''
    let innoOrganization = Buffer.from(`INNOVATION;${new Date()}`).toString('base64');
    await axios
      .post(process.env.REACT_APP_BASE_URL + 'v1/csrportal/idencryption', request, {headers: {
        'X-Tenant-Id': innoOrganization,    
        Authorization: 'Bearer ' + localStorage.getItem('innobearerToken')
    }})
      .then(resp => {
        let encriptedData = resp.data
        console.log('encriptedData====', encriptedData)
        response = encodeURIComponent(encriptedData)
        console.log('response encriptedData====', response)
      })
      .catch(err => {
        console.log('Internal server error.')
      })
    return await response
  }

    encryptData = async request => {
        // props.toggleGlobalLoader(true);
        let query = await this.encryptMemberId(request)
    
        return query
      }
    

      getCommonModuleURL = (type, query) => {
          let token = localStorage.getItem('bearerToken')
          return process.env.REACT_APP_SHARED_MODULE_URL + 'RecurringTransaction?query=' + query + '&token=' + token
        // }
      }
    
   
       
    
       
      
       
      
    


    generateUrl(val){
        this.setState({loading:true})
        var obj={
            "clientId":this.state.cid,
            "associationId":this.state.aid,
            "brokerId":this.state.bid
        }
        commomPost('/encrypt/encryptData',obj)
            .then(res=>{
                if(res && res.response){
                    this.setState({url:this.state.baseUrl+'#state='+res.response,loading:false})
                }
            })
    }

    handleBack = () =>{
        this.props.onClick();
    }

   
    
 

    render(){
        return (
           
            <div  style={{ width : '100%',overFlow:'hidden'}}>
                {/* ,backgroundColor:'#bfbfbf', height:'550px',*/}
                {
                        this.state.loaderShow ? <Loader></Loader> : ''
                    }

                <div style={customStyle.HomeContainer}>
                    <div style={customStyle.HomeMAinChild}>
                    <iframe
                            src={this.state.commonModuleURL}
                            title="Common Module"
                        
                            style={{
                            width: '100%',
                            height: "350px",
                            border: 'none',
                            margin: 'auto'
                            }}
                        ></iframe>
                     
                       
                    </div>
                </div>
                {/* <Footer /> */}
                {/* <div style={{marginTop:'-46px'}}>
                    <Footer />
                </div> */}
            </div>
        );
    }
}
export default RecurringTransaction;